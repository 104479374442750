import { EditVersionType, LocationType } from '@/common/enums';
import { IAssetEditVersion, ICompletionReportRecord, IMutateCrew, IMutateParticipant } from '@/common/types/asset';
import { IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import { httpClient } from './httpClient';
import { IAddCastToAsset, IApiErrorModel, IAssetAddEditVersionCast, IAssetRecord, ICastRecord, ICastStackPositionUpdateModel, IDeleteCrew, IDeleteParticipant, IEditVersionBasicDetail, IEditVersionBasicDetailPayload, ILocation, ILocationMutate, IOperationResultModel, IUpdateCastToAsset } from './models/asset';
import { IDeleteDocumentValue, IDocumentViewModel } from './models/company';
import { ICompletionRepCrewRecord, ICompletionRepParticipantRecord, ICrMutationPayload } from './models/completionReport';
import { IDocumentValue } from './models/contract';
import { ca } from 'date-fns/locale';

export const addCompletionReport = (payload: ICrMutationPayload) => {
	return httpClient.post('/CompletionReport/Add', payload);
};

export const editCompletionReport = (completionReportId: string, payload: ICrMutationPayload) => {
	return httpClient.put<IOperationResultModel>(`/CompletionReport/Update/${completionReportId}`, payload);
};

export const editCompletionReportInternalNote = (completionReportId: string, completionReportInternalNote: string) => {
	return httpClient.put<IOperationResultModel>(`/CompletionReport/Update/InternalNote/${completionReportId}`, completionReportInternalNote);
};

export const getCompletionReportById = (completionReportId : string) => {
	return httpClient.get<ICompletionReportRecord>(`/CompletionReport/GetById/${completionReportId}`);
};

export const getAllCompletionReport = (filters : IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompletionReportRecord>>('/CompletionReport/GetBy', filters);
};

export const deleteCompletionReportById = (completionReportId: string) => {
	return httpClient.delete<IApiErrorModel>(`/CompletionReport/${completionReportId}`);
};

export const getAllCompletionReportLocation = (completionReportId: string, filters : IFilterModel) => {
	return httpClient.post<IPagedResultModel<ILocation>>(`/CompletionReport/Location/GetBy/${completionReportId}`, filters);
};

export const getCompletionReportLocationGetById = (locationId : string) => {
	return httpClient.get<ILocation>(`/CompletionReport/Location/GetById/${locationId}`);
};

export const deleteCompletionReportLocationById = (locationId: string) => {
	return httpClient.delete<IApiErrorModel>(`/CompletionReport/Location/Delete/${locationId}`);
};

export const addCompletionReportLocation = (completionReportId: string, payload: ILocationMutate) => {
	return httpClient.post<IOperationResultModel>(`/CompletionReport/Location/Save/${completionReportId}`, payload);
};

export const editCompletionReportLocation = (locationId: string, payload: ILocationMutate) => {
	return httpClient.put<IOperationResultModel>(`/CompletionReport/Location/Update/${locationId}`, payload);
};

export const getCompletionReportNextLocation = (completionReportId: string, locationType: LocationType = LocationType.Filming) => {
	return httpClient.get<string>(`/CompletionReport/Location/NextNumber/${locationType}/${completionReportId}`);
};

export const getCompletionNextEditVersion = (completionReportId: string, editVersionType: EditVersionType = EditVersionType.Edit) => {
	return httpClient.get<string>(`/CompletionReport/EditVersion/NextEditVersion/${editVersionType}/${completionReportId}`);
};

export const getAllCompletionEditVersion = (completionReportId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetRecord>>(`/CompletionReport/EditVersion/GetBy/${completionReportId}`, filters);
};

export const addCompletionEditVersion = (completionReportId: string, payload: IAssetEditVersion) => {
	return httpClient.post<IOperationResultModel>(`/CompletionReport/EditVersion/Add/${completionReportId}`, payload);
};

export const deleteCompletionRepEditVersion = (editVersionId: string) => {
	return httpClient.delete(`/CompletionReport/EditVersion/Delete/${editVersionId}`);
};

export const addCastToCompletionRep = (payload: IAddCastToAsset) => {
	return httpClient.post('/CompletionReport/Cast/Add', payload);	
};

export const getAllCompletionRepCast = (completionReportId: string, isEditVersion: boolean, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICastRecord>>(`/CompletionReport/Cast/GetAll/${completionReportId}/${isEditVersion}`, filters);
};

export const updateCompletionRepCastDetail = (payload: IUpdateCastToAsset) => {
	return httpClient.post('/CompletionReport/Cast/Update', payload);
};

export const updateCompletionRepCastListStackPosition = (payload: ICastStackPositionUpdateModel) => {
	return httpClient.post('/CompletionReport/Cast/StackPosition', payload);
};

export const deleteCompletionRepCast = (body: IDeleteCrew) => {
	return httpClient.deleteV2('/CompletionReport/Cast/Delete', {headers: {}, body});
};

export const getAllCompletionRepEditVersionBasicDetail = (payload: IEditVersionBasicDetailPayload) => {
	return httpClient.post<IEditVersionBasicDetail>('/CompletionReport/Cast/GetEditVersionCastDetail', payload);
};

export const addCompletionRepEditVersionCast = (payload: IAssetAddEditVersionCast) => {
	return httpClient.put('/CompletionReport/Cast/EditVersionCast/Add', payload);
};

export const mutateCompletionRepCrew = (payload: IMutateCrew) => {
	return httpClient.post('/CompletionReport/Crew/Mutate', payload);
};

export const getAllCompletionRepCrew = (completionReportId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompletionRepCrewRecord>>(`/CompletionReport/Crew/GetBy/${completionReportId}`, filters);
};

export const getAllCompletionRepCrewIds = (completionReportId: string) => {
	return httpClient.get<string[]>(`/CompletionReport/Crew/GetAllIds/${completionReportId}`);
};

export const deleteCompletionRepCrew = (body: IDeleteCrew) => {
	return httpClient.deleteV2('/CompletionReport/Crew/Delete', {headers: {}, body});
};

export const mutateCompletionRepParticipant = (payload: IMutateParticipant) => {
	return httpClient.post('/CompletionReport/Participant/Mutate', payload);
};

export const getAllCompletionRepParticipant = (completionReportId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompletionRepParticipantRecord>>(`/CompletionReport/Participant/GetAll/${completionReportId}`, filters);
};

export const getAllCompletionRepLinkedParticipant = (completionReportId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompletionRepParticipantRecord>>(`/CompletionReport/Participant/GetAllLinked/${completionReportId}`, filters);
};

export const deleteCompletionRepParticipant = (body: IDeleteParticipant) => {
	return httpClient.deleteV2('/CompletionReport/Participant/Delete', {headers: {}, body});
};

export const getAllCompletionRepDocumentList = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<IDocumentViewModel>>(`/CompletionReport/Document/GetBy/${id}`, filterModel);
};

export const createCompletionRepDocument = (payload: IDocumentValue) => {		
	return httpClient.post('/CompletionReport/Document/Save', payload);
};

export const deleteCompletionRepDocument = (payload: IDeleteDocumentValue) => {
	return httpClient.delete('/CompletionReport/Document/Delete', payload);
};

export const addCompletionRepLinkedElement = (payload) => {
	return httpClient.post('/CompletionReport/LinkedElement/Add', payload);
};

export const updateCompletionRepLinkedElement = (payload) => {
	return httpClient.post('/CompletionReport/LinkedElement/Update', payload);
};

export const getAllCompletionRepLinkedElement = (filterModel: IFilterModel, completionReport: string) => {
	return httpClient.post<IPagedResultModel<any>>(`/CompletionReport/LinkedElement/GetBy/${completionReport}`, filterModel);
};

export const unLinkCompletionRepLinkedAsset = (payload) => {
	return httpClient.post('/CompletionReport/LinkedElement/Unlink', payload);
};

export const downloadCompletionReport = (exportObject: any) => {
	const queryParams = new URLSearchParams({
		includes: exportObject.includes.join(','),
		format: exportObject.format ?? '',
	});

	(async() => {
		try {
			const response =  await httpClient.get<Blob>(`/CompletionReport/Export/${exportObject.completionReportId}?${queryParams.toString()}`, {}, {
				responseType: 'blob', 
				// withCredentials: true,
			});
			const headerval = response.headers['content-disposition'] ?? response.headers['Content-Disposition'];
			if (headerval != null) {
				let filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
				filename = decodeURI(filename);
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				window.URL.revokeObjectURL(url);
				link.remove();
			} else {
				console.error('Error downloading', response);
			}
		}
		catch (error) {
			console.error('Error downloading completion report', error);
		}
	})();
};