import { NabuCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { DefaultPageSize } from '@/common/constants/constants';
import { columnsType, ITableActionMenuModel } from '@/common/types/dataTable';
import { getVisibleTableColumnDefs } from '@/common/utils/utils';
import store from '@/store';
import { setIsEditOpen } from '@/store/app/reducer';
import { ArrowDropDown, ArrowDropUp, Block, BlockOutlined, HelpOutline } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Collapse, FormControl, FormLabel, Grid, Icon, IconButton, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import NabuSelect from '../../atoms/select/select';
import { TableActionMenu } from './actionMenu';
import styles from './dataTable.module.scss';
import { TableBodyWrapper, TableRowWrapper, TSortableAdditionalDetail } from './tableSortWrapper';

export interface IDataTableProps {
	editIndex?: number;
	columns: columnsType[],
	rows: Record<string, any>[],
	totalRowsCount: number,
	actionsMenu?: ITableActionMenuModel[],
	contextActionsMenu?: ITableActionMenuModel[],
	children?: any,
	onChange?: (params: any) => void,
	selectionMode?: 'multiple' | 'single',
	onRowSelectionChange?: (rows: any[]) => void,
	onOwnerChange?: (record: any, event: any) => void,
	className?: string,
	onAdminChange?: (record: any, event: any) => void,
	'data-cy'?: string,
	selectedRows?: any[],
	defaultSortField?:string,
	height?: any;
	hideRowsCount?: boolean;
	hideDetailRow?: string;
	hideExpansion?: boolean;
	defaultSortDescending?: boolean;
	isSelectable?: (row: any) => boolean;
	footerStats?: string;
	projectFields?: string[];
	componentName?:string;
	rowsPerPage: number;
	currentPage: number;
    inlineRows?: {
        isOpen: boolean;
        slot: string;
        rowIndex: number;
    };
    inlineEditRows?: {
        isOpen: boolean;
        slot: string;
        rowIndex: number;
    };
	onPageChange?: (currentPage: number) => void;
    showNoAction?: boolean;
    deletedKey?: string; // TO identify the deleted records in the row list
	noRecordText?: string;
	onNumberOfRowsPerPageChange?: (numberOfRowsPerPage: number) => void;
	tableName?: string;
	shouldHandleScrollToView?: boolean;
	shouldHideTableHeader?: boolean;
	hidePagination?: boolean;
	onRowSorted?: (rows: any[], additionalDetail?: TSortableAdditionalDetail) => void;
	isRowSortDisabled?: (record: Record<string, any> | undefined) => boolean;
}

// eslint-disable-next-line react/display-name
const DataTable = forwardRef((props: IDataTableProps, ref: any) => {
	const DEFAULT_COLUMN_WIDTH = '200px';
	const TOOLTIP_MAX_LENGTH = 20;
	const [selectedRows, setSelectedRows] = useState(props.selectedRows || []);
	const [pageSize, setPageSize] = useState(props?.rowsPerPage || DefaultPageSize);
	const [,setSelectedRowIds] = useState([] as any[]);
	const { columns, rows, actionsMenu, contextActionsMenu, onChange, selectionMode, onRowSelectionChange, hideExpansion } = props;
	let dataColumns = columns?.filter(c => c.id !== 'actions') ||  [];
	dataColumns = getVisibleTableColumnDefs(dataColumns);
	const visibleColumns = getVisibleTableColumnDefs(columns,);
	const [tableState, setTableState] = useState({
		sortFields: props.defaultSortField,
		sortDescending: props.defaultSortDescending || false,
		disableHoverListener:false,
		limit: DefaultPageSize
	} as {
		sortFields?: string;
		sortDescending: boolean;
		disableHoverListener: boolean;
		limit: number;
		});
		
	const pageParam = props?.tableName ? `${props?.tableName}TablePage` : 'TablePage';
	const [pageSearchParams, setPageSearchParams] = useSearchParams({
		[pageParam]: '1',
	});

	const [expandedRowIndexes, setExpandedRowIndexes] = useState<number[]>([]);
	const pageCount = Math.ceil(props.totalRowsCount / pageSize);
	
	useEffect(() => {
		setTableState((currentState) => {
			return {
				...currentState,
				disableHoverListener:false
			};
		});
	}, [tableState.sortFields, tableState.sortDescending, tableState.limit, props.projectFields]);

	useEffect(() => {
		setSelectedRowIds(selectedRows.map((row) => row.id));
	}, [selectedRows]);
	
	useImperativeHandle(ref, () => ({
		resetSelectedRows: () => {
			setSelectedRows([]);
		},
	}));

	const onSortApplied = (sortKey?: string) => {
		let sortFields = sortKey;

		onChange && onChange({
			sortFields: sortKey,
			limit: tableState.limit,
			sortDescending: tableState.sortDescending
		});

		if (!tableState.sortDescending) {
			sortFields = `${sortFields}`;
		}else sortFields = `-${sortFields}`;

		setPageSearchParams(
			(prev) => {
				prev.set(pageParam, '1');
				return prev;
			},
			{ replace: true }
		);

		const sortDescending = !tableState.sortDescending;

		setTableState((currentState) => {
			return {
				...currentState,
				sortDescending: sortDescending,
				sortFields: sortFields
			};
		});
	};

	const disableHover = () => {
		setTableState((currentState) => {
			return {
				...currentState,
				disableHoverListener:!(tableState.disableHoverListener)
			};
		});
	};

	const onSelectAllRows = () => {
		if (selectedRows.length === rows.length) {
			setSelectedRows([]);
			onRowSelectionChange && onRowSelectionChange([]);
		} else {
			setSelectedRows(props.isSelectable ? rows.filter(r=> props.isSelectable && props.isSelectable(r)) : rows);
			onRowSelectionChange && onRowSelectionChange(props.isSelectable ? rows.filter(r=> props.isSelectable && props.isSelectable(r)) : rows);
		}
	};

	const onMultiRowSelect = (row: any) => {
		let newSelectedRows = [...selectedRows];
		if (newSelectedRows.some(newRow=> JSON.stringify(row) === JSON.stringify(newRow))) {
			newSelectedRows = newSelectedRows.filter((newRow) => JSON.stringify(newRow) !== JSON.stringify(row));
		} else {
			newSelectedRows.push(row);
		}
		setSelectedRows(newSelectedRows);
		onRowSelectionChange && onRowSelectionChange(newSelectedRows);
	};

	const onRowSelect = (row: any) => {
		if (selectionMode === 'single') {
			let rows = [...selectedRows];
			if (rows.includes(row)) {
				rows = rows.filter((newRow) => JSON.stringify(newRow) !== JSON.stringify(row));
			} else {
				rows = [row];
			}
			setSelectedRows(rows);
			onRowSelectionChange && onRowSelectionChange(rows);
		} else {
			onMultiRowSelect(row);
		}
	};

	const expandRow = (rowIndex: number) => {
		let indexes = [...expandedRowIndexes];
		if (expandedRowIndexes.includes(rowIndex)) {
			indexes = expandedRowIndexes.filter(index => index !== rowIndex);
		} else {
			indexes.push(rowIndex);
		}
		setExpandedRowIndexes(indexes);
	};

	const handlePageChange = (_, page: number) => {		
		setPageSearchParams(
			(prev) => {
				prev.set(pageParam, page?.toString() || '1');
				return prev;
			},
			{ replace: true }
		);
	};

	const getToolTipTitle = (rowValue) => (typeof rowValue === 'string' || typeof rowValue === 'number') && `${rowValue}`?.length > TOOLTIP_MAX_LENGTH ? rowValue : undefined;
	
	useEffect(() => {
		const page = parseInt(pageSearchParams.get(pageParam) || '1');
		props.onPageChange && props.onPageChange(page > 1 ? page - 1 : 0);
	}, [pageSearchParams]);
	
	const childrenArray = React.Children.toArray((props as any).children);
	const childrens: any = childrenArray.length && childrenArray.reduce((prev, next: any) => ({ ...prev, [next.props.slot]: next }), {});
	const detailRow: any = childrenArray.find((c: any) => c.props.slot === 'row-detail');
	const isDetailRowVisibleCallBack = detailRow?.props?.isVisible;
	const height = props.height || { maxHeight: 'calc(100vh - 275px)' };
	return (
		<>
			<div className={styles.datatableWrapper} data-cy='tableWrapperMain'>
				<TableContainer sx={{height}} data-cy='tableContainerMain'>
					<Table
						className={`${styles.datatable} ${props.className}`}
						stickyHeader={true}
						data-cy={`${props['data-cy']}`}
						aria-label='sticky table'
						size='small'
						sx={{height: props.rows?.length===0 ?'100%!important':'auto'}}
					>
						{ (!expandedRowIndexes?.length || !props?.shouldHideTableHeader) && <TableHead className={styles.datatableHeader} data-cy='table-head'>
							<TableRow data-cy='theadRowMain'>
								{
									(!!props?.onRowSorted && !props.inlineRows?.isOpen) && <TableCell width={'10px'} />
								}
								{selectionMode && (
									<TableCell key="selection"  width={'10px'}>
										{selectionMode === 'multiple' && (
											<NabuCheckbox
												checked={selectedRows.length === rows.length && selectedRows.length > 0}
												indeterminate={selectedRows.length !== rows.length && selectedRows.length > 0}
												onChange={onSelectAllRows}
												data-cy="select-all-checkbox"
											/>
										)}
									</TableCell>
								)}
								{ !hideExpansion && detailRow && <TableCell key="expand" style={{
									minWidth: 10,
									textAlign: 'right',
									backgroundColor: '#fafafa'
								}}
								></TableCell>
								|| hideExpansion && <TableCell key={`expand-${+new Date()}`}></TableCell>
								}							
								{
									visibleColumns.map((column: any) => {
										return (
											<Tooltip key={`${column.id}${column.label}${+new Date()}`} title={column.tooltipTitle} placement='bottom-start'>
												<TableCell key={`${column.id}${column.label}${+new Date()}`} style={{
													minWidth: column.minWidth,
													textAlign: (column.textAlign as any) ?? 'left',
													backgroundColor: '#fafafa',
													cursor: column.allowSort ? 'pointer' : 'auto',
													maxWidth: column.maxWidth,
													width: column.width
												}}
												data-cy={`table-head-${column.id}`}
												
												>
													{column.allowSort ? (
														<div className={styles.datatableHeaderCell} onClick={() => onSortApplied(column.sortKey)} data-cy={column.label}>
															{column?.headerHelperText?.trim() && (<Tooltip title={column?.headerHelperText?.trim() || ''}>
																<div style={{ display: 'inline-flex'}}>
																	<span>&nbsp;{column.label}</span>
																	{column?.showHelperTextIcon && <span>&nbsp;<HelpOutline fontSize='inherit' /></span>}
																</div>
															</Tooltip>) || column.label}
															{tableState.sortFields === `-${column.sortKey}` ? (
															//<Tooltip title="Sort Descending" arrow>
																<Icon
																	component={ArrowDropDown}
																	style={{ marginBottom: '-10px', fontSize:'34px', height: '26px' }}
																/>
																//	</Tooltip>
															) : <Icon
																component={ArrowDropUp}
																style={{ marginBottom: '-10px', fontSize:'34px', height: '26px' }}
															/>
																// </Tooltip>
															}
														</div>
													) : (
														<div>
															{column?.headerHelperText?.trim() && (<Tooltip placement='top-start' title={column?.headerHelperText?.trim() || ''}>
																<div>
																	<span>&nbsp;{column.label}</span>
																	{column?.showHelperTextIcon && <span>&nbsp;<HelpOutline fontSize='inherit' /></span>}
																</div>
															</Tooltip>) || column.label}
														</div>
													)}
												</TableCell>
											</Tooltip>
										);
									})}
							</TableRow>
						</TableHead>}
						<TableBody className={styles.datatableBody}  data-cy="tableContents">
							<TableBodyWrapper 
								currentPage={props?.currentPage || 0} 
								pageSize={pageSize}
								rows={rows} 
								onRowSorted={!props.inlineRows?.isOpen ? props?.onRowSorted : undefined}
							>
								{
									rows?.length ? (rows.map((row: any, rIdx: number) => (
										<>
											<TableRowWrapper 
												id={rIdx} 
												isSortable={!!props?.onRowSorted && !props.inlineRows?.isOpen} 
												isDisabled={expandedRowIndexes?.length > 0 || (props?.isRowSortDisabled && props?.isRowSortDisabled(row))} 
												key={`drag1-${rIdx}-${row.id}`}  
												className={ props.deletedKey && row[props.deletedKey] && 'row-deleted'}
											>
												{props.editIndex !== rIdx && <>
													{selectionMode && (
														<TableCell key={`checkbox-${rIdx}-${row.id}`} width={'10px'}>
															<NabuCheckbox
																checked={selectedRows.some(selectedRow=> JSON.stringify(row) === JSON.stringify(selectedRow))}
																onChange={() => onRowSelect(row)}
																disabled = { props.isSelectable? !props.isSelectable(row) : false}
																data-cy="select-row-checkbox"
															/>
														</TableCell>
													)}
													{
														detailRow && !props.inlineRows?.isOpen &&
														<TableCell key={`expand-${rIdx}-${row.id}`} className={styles.datatableCellExpand}>
															{(!isDetailRowVisibleCallBack || (isDetailRowVisibleCallBack(row))) ? <IconButton 
																aria-label="expand row"
																size="small"
																ref={(ele) => expandedRowIndexes.at(-1) === rIdx && props?.shouldHandleScrollToView === true && ele?.scrollIntoView({ behavior: 'smooth' })}
																onClick={() => {
																	store.dispatch(setIsEditOpen(false));
																	expandRow(rIdx);
																}}
															>
																{expandedRowIndexes.includes(rIdx) ?  <KeyboardArrowDownIcon sx={{fill:'#212529'}}/> : <KeyboardArrowRightIcon sx={{fill:'#212529'}}/>}
															</IconButton>
																: <IconButton 
																	size="small"
																	disabled
																>
																	<Block sx={{fill:'#ffffff00'}}/>
																</IconButton>}
														</TableCell>
													}
													{dataColumns.map((column, cIdx) => {
														return childrens[column.id] ? (
															<TableCell key={`${column.id}-${cIdx}`} style={{
																minWidth: column.minWidth,
																textAlign: (column.textAlign as any) ?? 'left',
																padding: '0',
																paddingLeft: '5px',
																maxWidth: column.maxWidth ?? DEFAULT_COLUMN_WIDTH,
															}}  
															data-cy={`test-${column.id}`}
															className={ (expandedRowIndexes.includes(rIdx) && props?.shouldHideTableHeader) ? 'stickyAvatar' : undefined}
															>
																<>
																	{expandedRowIndexes.includes(rIdx) && props?.shouldHideTableHeader && <p className={column?.rowDetailHeaderClassName}>{column?.label}</p>}
																	{React.cloneElement(childrens[column.id], {...row, rowIndex: rIdx})}
																</>
															</TableCell>
														) : (
															<TableCell key={`${column.id}-${cIdx}`} data-cy={`test-${column.id}`} style={{
																minWidth: column.minWidth,
																textAlign: (column.textAlign as any) ?? 'left',
																maxWidth: DEFAULT_COLUMN_WIDTH,
																
															}}
															className={ (expandedRowIndexes.includes(rIdx) && props?.shouldHideTableHeader) ? 'stickyAvatar' : undefined}
															>
																
																<>
																	{expandedRowIndexes.includes(rIdx) && props?.shouldHideTableHeader && <p className={styles.talentNameSub}>{column?.label}</p>}
																	{typeof row[column.id] === 'boolean'
																		? row[column.id] === true
																			? 'YES'
																			: 'NO'
																		: (
																			<Tooltip title={!column?.hideTooltip ? getToolTipTitle(row[column.id]) : undefined} placement='top-start'>
																				<span>{row[column.id]}</span>
																			</Tooltip>
																		)}
																</>
															</TableCell>
														);
													})}
													{
														actionsMenu && actionsMenu.length > 0 && (
															<TableCell width={50} key={`actions-${rIdx}-${row.id}`} className={styles.datatableCellAction}>
																<>
																	<div className={styles.fixedCell}>
																		{ actionsMenu.some(action => (!action.visible || (action.visible && action.visible(row))) === true) ? 
																			(actionsMenu?.map((action, idx) => {
																				return (!action.visible || (action.visible && action.visible(row))) && 
																				<Tooltip key={`tooltip-${idx}`} title={(action.title(row) || '').toString()} componentsProps={{ tooltip: { sx: { bgcolor: 'common.white', color: 'black', borderStyle: 'solid', borderWidth: '1px', }, }, }} followCursor={false} placement={'bottom-start'} enterNextDelay={500}>
																					<IconButton
																						className={`${((action?.disabled && action.disabled(row)) == true) ? styles.btnDisabled : ''}`}
																						key={`icon-button-${idx}`}
																						disabled={action.disabled && action.disabled(row)}
																						onClick={(event: any) => {
																							row.rowIndex = rIdx;
																							row.isMultipleRowSelected = selectedRows.length > 1 ? true : false;
																							action.onClick && action.onClick(row, event);
																						}
																						}
																						data-cy={`${action['data-cy']}_row-${rIdx}-index-${idx}`}
																						name={(action.title(row) || '').toString()}
																					>
																						{!(typeof action.iconColor === 'function') && <Icon key={`icon-${idx}`} component={action.icon(row)} color={action.iconColor} />}
																						{(typeof action.iconColor === 'function') && <Icon className={styles.notification} key={`icon-${idx}`} component={action.icon(row)} color={action.iconColor(row)}/>}	
																					</IconButton>
																				</Tooltip>;
																			})
																			) : (
																				(props?.showNoAction !== false) && <Tooltip key={'tooltip-noAction'} title={'No possible actions'} componentsProps={{ tooltip: { sx: { bgcolor: 'common.white', color: 'black', borderStyle: 'solid', borderWidth: '1px', }, }, }} followCursor={false} placement={'bottom-start'} enterNextDelay={500}>
																					<IconButton
																						key={'icon-button-noAction'}
																						disableRipple={true}
																						data-cy={'data-cy-noAction'}
																					>
																						<BlockOutlined />
																					</IconButton>
																				</Tooltip>
																			)
																		}
																		{contextActionsMenu && contextActionsMenu.length > 0 && contextActionsMenu.some(action=> (!action.visible || (action.visible && action.visible(row)) === true)) ? (
																			<Tooltip
																				key={`table-action-menu-tooltip-${rIdx}-${row.id}`}
																				title={'More'}
																				PopperProps={{ disablePortal: true }}
																				componentsProps={{ tooltip: { sx: { bgcolor: 'common.white', color: 'black', borderStyle: 'solid', borderWidth: '1px', }, }, }} 
																				followCursor={false}  
																				placement={'bottom-start'}
																				enterNextDelay={500}
																				disableHoverListener={tableState.disableHoverListener}
																			>
																				<span onClick={disableHover}>
																					<TableActionMenu
																						key={`table-action-menu-${rIdx}-${row.id}`}
																						menus={contextActionsMenu}
																						record={{...row, rowIndex: rIdx}}
																					/>
																				</span>
																			</Tooltip>
																		) :  (
																			<IconButton
																				key={'icon-button-noAction'}
																				disableRipple={true}
																				data-cy={'data-cy-noAction'}
																				style={{ display: 'none'}}
																			>
																				<BlockOutlined />
																			</IconButton>
																		)}
																	</div>
																</>
															</TableCell>
														)
													}									
												</>}
											</TableRowWrapper>
											{detailRow && !props.inlineRows?.isOpen && expandedRowIndexes.includes(rIdx) && <TableRow key={`row-details-${rIdx}`} dat-cy="Row Detail">
												<TableCell 
													key={`detail-${rIdx}-${row.id}`} 
													className={styles.expandButtonControl} 
													style={{ paddingBottom: 0, paddingTop: 0, paddingLeft:0, paddingRight:0, }} 
													colSpan={columns.length + 1 + (props.selectionMode? 1 : 0) + (!props?.onRowSorted ? 0 : 1)}
												>
													<Collapse in={expandedRowIndexes.includes(rIdx)} timeout="auto" unmountOnExit>
														<>
															{React.cloneElement(childrens['row-detail'], {...row, rowIndex: rIdx})}
														</>
													</Collapse>
												</TableCell>
											</TableRow>}
											{props.inlineRows?.isOpen && rIdx === props.inlineRows.rowIndex && <TableRow key={`row-details-${rIdx}`}>
												<TableCell key={`detail-${rIdx}-${row.id}`} style={{ paddingBottom: 0, paddingTop: 0, paddingLeft:0, paddingRight:0, overflow: 'visible' }} colSpan={columns.length + 1 + (props.selectionMode? 1 : 0)}>
													<Collapse in={props.inlineRows?.isOpen} timeout="auto" unmountOnExit>
														<>
															{React.cloneElement(childrens[props?.inlineRows?.slot ?? ''], {...row, rowIndex: rIdx})}
														</>
													</Collapse>
												</TableCell>
											</TableRow>}
											{props.inlineEditRows?.isOpen && rIdx === props.inlineEditRows.rowIndex && <TableRow key={`row-details-${rIdx}`}>
												<TableCell key={`detail-${rIdx}-${row.id}`} style={{ paddingBottom: 0, paddingTop: 0, paddingLeft:0, paddingRight:0, overflow: 'visible' }} colSpan={columns.length + 1 + (props.selectionMode? 1 : 0)}>
													<Collapse in={props.inlineEditRows?.isOpen} timeout="auto" unmountOnExit>
														<>
															{React.cloneElement(childrens[props?.inlineEditRows?.slot ?? ''], {...row, rowIndex: rIdx})}
														</>
													</Collapse>
												</TableCell>
											</TableRow>}
										</>
									)
									)) : (
										<TableRow>
											<TableCell
												variant='body'
												colSpan={columns.length}
												className={styles.noRecord}
											>
												<Typography variant='overline'> {props?.noRecordText ? props.noRecordText : 'No Records Found' }</Typography>
											</TableCell>
										</TableRow>
									)
								}
							</TableBodyWrapper>					
						</TableBody>
					</Table>
				</TableContainer>
				{ !props?.hidePagination && (
					<Stack spacing={0} direction="row" justifyContent="center" alignItems="center" sx={{p:0}} className={styles.stackTable}>
						<Grid item container alignItems={'center'} alignContent={'center'} >
							<Grid item sm={12} md={6.5}>
								{(props.totalRowsCount >= (props?.rowsPerPage ? props?.rowsPerPage : pageSize)) && (<div className={styles.paginationSection}>
									<Pagination 
										count={pageCount} 
										variant='outlined' 
										shape='rounded'
										onChange={handlePageChange}
										page={props.currentPage ? props.currentPage + 1 : 1}
									/>
								</div>)}
							</Grid>
							{(props.totalRowsCount >= DefaultPageSize) && (
								<>
									<Grid item sm={12} md={3.5} dir="rtl">
										<FormLabel data-cy='rows-per-page' sx={{pb:0}}>&nbsp;&nbsp;	Rows Per Page  <sup></sup>
										</FormLabel>
									</Grid>
									<Grid item sm={12} md={2}>
										<FormControl fullWidth sx={{pt:2, pr:2}}>
											<NabuSelect
												onChange={(event: any) => {
													if(props.onNumberOfRowsPerPageChange){
														const newPageNumber = Number(event.target.value);
														props.onNumberOfRowsPerPageChange(newPageNumber);
														setPageSize(newPageNumber);
														handlePageChange(undefined, 1);
													}
												}}
												value={pageSize}
												placeholder='Rows Per Page'
												shouldOverrideDefaultSort={true}
												defaultValue={pageSize}
												options={[
													{
														label: '5',
														value: 5,
													},
													{
														label: '25',
														value: 25,
													},
													{
														label: '50',
														value: 50,
													},
													{
														label: '100',
														value: 100,
													}
												]}
											/>
										</FormControl>
									</Grid>
								</>)}
						</Grid>
					</Stack>
				)}
			</div>
		</>
	);
});

export default DataTable;