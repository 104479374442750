import * as schema from 'yup';
import { ICompanyName } from '@/common/types/company';
import { mutateCATheftPreventionForm, mutateMinorTrustForm, mutateNYTheftPreventionForm, mutateW4Form, mutateW8BenShortForm, mutateW9BenShortForm } from '@/services/contract';
import { ISessionInvoice, IUnionAgreement } from '@/services/models/asset';
import { IPersonName } from '@/services/models/person';
import store from '@/store';
import { IUserProfileModel } from '@/store/auth/types';
import { HttpStatusCode } from 'axios';
import { Country as CountriesData, Country, State, State as StatesData } from 'country-state-city';
import { add, addDays, differenceInDays, differenceInWeeks, format } from 'date-fns';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ABBREVIATED_CAST_CATEGORIES_MAPPING, ASSET_USE_RIGHT_CYCLE_UNIT, CONTRACT_FORM, CompanyNameType, CompanyTypes, ContractAgreementMapping, DateSessionMapping, ElementTypes, ExpirationType, ExpirationTypeEmailTemplate, SnippetFields, UNION_AGREEMENTS, UNION_TYPES, personTypes } from '../constants/constants';
import { CAST_ABBREVIATION_CONTROL, COMPANY_INFO_TAB, ELEMENT_INFO_TAB, PEOPLE_INFO_TAB, SessionCycleLength } from '../enums';
import { ISessionInvoiceForm } from '../types/asset';
import { IContractAdditionalFormMutatePayload, IContractAdditionalFormMutateReturn } from '../types/contract';
import { columnsType } from '../types/dataTable';
import { continents } from 'countries-list';

export const hideAllDigitsExceptLast = (value: string, offset: number) => {
	return value?.slice(0, -offset).replace(/\d/g, '*') + value?.slice(-offset);
};

export const replaceSnippetFieldsWithInput = (value: string) => {
	SnippetFields.forEach((field: any) => {
		value = value?.replaceAll(field.key, `<input id="replace" placeholder="${field.label}"/>`);
	});

	return value;
};

export const parseAccessToken = (token: string): IUserProfileModel => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	const userInfo: any = JSON.parse(jsonPayload);
	return {
		...userInfo,
		email: userInfo.email,
	};
};

const objectHasProperty = (object: any, key: string): boolean => {
	return Object.prototype.hasOwnProperty.call(object, key) as boolean;
};

export const FormateNumber = (value: number, minDigits: number = 2, maxDigits: number = 2): string => {
	const userInfo: any = localStorage.getItem('user_info');
	const userLanguage = JSON.parse(userInfo);
	return value?.toLocaleString(userLanguage.language, { minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits });
};

export const displayDate = (date: any) => {
	if(date != null){
		date = new Date(date);
		const userTimezoneOffset = date.getTimezoneOffset() * 60000;
		date = new Date(date.getTime() + userTimezoneOffset);
		return date != null && `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}` || '';
	}
};

export const getVisibleTableColumnDefs = (tableColumnsDef: columnsType[]): columnsType[] => {
	for (const tableColumnDef of tableColumnsDef) {
		// if prop is not provided
		if (!objectHasProperty(tableColumnDef, 'visible'))
			tableColumnDef.visible = true;
	}
	return tableColumnsDef.filter(column => column.visible);
};

export const displayPhoneNumber = (phoneNumber: any) => {
	const countryCode: any = phoneNumber?.countryCode;
	const number: any = phoneNumber?.number;
	const extension: any = phoneNumber?.extension;
	const label:any=phoneNumber?.phoneNumberLabel;
	return number ? (formattedPhoneNumber(countryCode, number,label) + (extension ? ` Ext.${extension}` : '') + (label ? ' ' + label : '')) : '';
};

export const displayPhoneNumberWithoutLabel = (phoneNumber: any) => {
	const countryCode: any = phoneNumber?.countryCode;
	const number: any = phoneNumber?.number;
	const extension: any = phoneNumber?.extension;
	return number ? (formattedPhoneNumber(countryCode, number) + (extension ? ` Ext.${extension}` : '')) : '';
};

export const displayPhoneNumberLabel = (phoneNumber: any) => `${phoneNumber?.phoneNumberLabel || ''}`;

export const displayEmail = (email: any) => {
	return email ? `${email.emailId} ${email.emailLabel}` : '';
};

export const displayOnlyEmail = (email: any) => {
	return email ? `${email.emailId}` : '';
};

export const displayOnlyEmailLabel = (email: any) => {
	return email ?  `${email.emailLabel || ''}` : '';
};

export const displayPersonName = (personName: any) => `${personName.firstName}${personName.middleName !== '' ? (' ' + personName.middleName) : ''}${personName.lastName !== '' ? (' ' + personName.lastName) : ''}`;

export const displayPersonType = (personType: any) => `${personType.personType}`;

export const displayAddress = (address: any): string => {
	const addressData: any = [];
	const state =StatesData.getStateByCodeAndCountry(address?.state, address?.country)?.name;
	const country = CountriesData.getCountryByCode(address?.country)?.name;

	if (address != null) {
		if (address?.line1?.trim())
			addressData.push(address.line1);
		if (address?.line2?.trim())
			addressData.push(address.line2);
		if (address?.city?.trim())
			addressData.push(address.city);
		if (address?.zipCode?.trim())
			addressData.push(address.zipCode);
		if (address?.state?.trim())
			addressData.push(state);
		if (address?.country?.trim())
			addressData.push(country);
	}
	return addressData?.join(', ')?.toUpperCase();
};

export const displayBaseAddress = (address: any): string[] => {
	const addressData: any = [];
	if (address != null) {
		if (address?.line1)
			addressData.push(address.line1.toUpperCase() + ', ');
		if (address?.line2)
			addressData.push(address.line2.toUpperCase() + ', ');
	}
	return addressData;
};

export const displayCityStateAndZipCode = (address: any) => {
	let result: string = '';

	if (address) {
		if (address?.city) {
			result += address?.city.toUpperCase() + ', ';
		}
		if (address?.state) {
			result += State.getStateByCodeAndCountry(address?.state ?? '', address?.country ?? '')?.name.toUpperCase() + ', ';
		}
		if (address?.zipCode) {
			result += address?.zipCode;
		}

		return result;
	}
};

export const getPersonMiddleName = (personName: any) => {
	const length = personName.split(' ').length;
	const nameArray = personName.split(' ');

	switch (length) {
	case 1: return '';
	case 2: return '';
	case 3: return nameArray[1];
	default: return '';
	}
};

export const getPersonFirstName = (personName: any) => {
	const length = personName.split(' ').length;
	const nameArray = personName.split(' ');

	switch (length) {
	case 1: return nameArray[0];
	case 2: return nameArray[0];
	case 3: return nameArray[0];
	default: return '';
	}
};

export const getPersonLastName = (personName: any) => {
	const length = personName.split(' ').length;
	const nameArray = personName.split(' ');

	switch (length) {
	case 1: return '';
	case 2: return nameArray[1];
	case 3: return nameArray[2];
	default: return '';
	}
};

export const is7MGClient: () => boolean = () => {
	const userContext = store.getState().userContext;
	const is7MilesClient =
		userContext?.userInfo?.is7MilesClient || userContext?.userAuthInfo?.is7MilesClient;
	return is7MilesClient ? true : false;
};

export const getCompanyDisplayName = (companyNames?: ICompanyName[]): string => (companyNames?.find((i) => i.nameType === 'DBAName')?.name || '') || (companyNames?.find((i) => i.nameType === 'legalName')?.name || '') || (companyNames?.find((i) => i.nameType === 'abbreviation')?.name || '');

export const getCompanyDBAName = (companyNames: ICompanyName[]): string => companyNames?.find((i) => i.nameType === 'DBAName')?.name || '';

export const getCompanyLegalName = (companyNames: ICompanyName[]): string => companyNames?.find((i) => i.nameType === 'legalName')?.name || '';

export const getCompanyAbbreviationlName = (companyNames: ICompanyName[]): string => companyNames?.find((i) => i.nameType === 'abbreviation')?.name || '';

export const sortCompanyNames = (a: any, b: any) => {
	const order = [CompanyNameType.legalName, CompanyNameType.abbreviation, CompanyNameType.dbaName];
	return order.indexOf(a.nameType) - order.indexOf(b.nameType);
};

export const debounce = (cb, delay: number = 1000) => {
	let timeout;

	return (...args) => {
		timeout && clearTimeout(timeout);
		timeout = setTimeout(() => {
			clearTimeout(timeout);
			cb(...args);
		}, delay);
	};
};

export const getNotificationText = (value: string, type: 'CREATE' | 'UPDATE' | 'DELETE' | 'FETCHING', isError: boolean = false): string => {
	const notifyValue = value.trim();

	const errorText = { 'CREATE': 'adding', 'UPDATE': 'updating', 'DELETE': 'deleting', 'FETCHING': 'fetching' };
	if (isError) return `'Error While ${errorText[type]} ${notifyValue}'`;

	switch (type) {
	case 'CREATE': return `'${notifyValue}' Added Successfully`;
	case 'UPDATE': return `'${notifyValue}' Details Updated Successfully`;
	case 'DELETE': return `'${notifyValue}' Deleted Successfully`;
	case 'FETCHING': return `'${notifyValue}' Fetched Successfully`;
	}
};

export const urlToBase64String = async (url: string) => {
	const data = await fetch(url);
	const blob = await data.blob();

	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
};

export const getCurrencySymbol = (currencyCode) => {
	try {
		const currencySymbol = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).formatToParts(1).find(part => part.type === 'currency')?.value ?? '';
		return currencySymbol;
	} catch (error) {
		return null;
	}
};

export const dataURLtoFile = (dataurl, filename) => {
	const arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[arr.length - 1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

export const isCompanySectionVisible = (tab: COMPANY_INFO_TAB, mainType: string) => {
	const visibletabs = mainType && CompanyTypes[mainType]?.visibleTabs;
	return visibletabs ? visibletabs.includes(tab) : false;
};

export const convertToTitleCase = (input: string) => {
	if(input.length > 0){
		return input.charAt(0).toUpperCase() + input.slice(1);
	}
};

export const notificationMessageForFormOpenState = (formName: string) => {
	return `${formName} form is open`;
};

export const getColumnData = (parsedData: any, index: number) => {
	return Object.values(parsedData[index]);
};

export const isElementSectionVisible = (tab: ELEMENT_INFO_TAB, elementType: string) => {
	const visibletabs = elementType && ElementTypes[elementType]?.visibleTabs;
	return visibletabs ? visibletabs.includes(tab) : false;
};

export const isElementProfilePropertyVisible = (property, elementType: string) => {
	const visibleProperties = elementType && ElementTypes[elementType]?.visibleProperties;
	return visibleProperties?.includes(property) ? true : false;
};

export const isPersonSectionVisible = (tab: PEOPLE_INFO_TAB, personTypeList: string[]) => {
	for (const personType of personTypeList) {
		const visibleTabs = personTypes[personType]?.visibleTabs || [];
		if (visibleTabs?.includes(tab)) return true;
	}
	return false;
};

export const getPersonFullName = (name: IPersonName | undefined) =>
	`${name?.firstName || ''} ${name?.middleName || ''} ${name?.lastName || ''
	}`.trim();

export const getPersonAliasFullName = (name: IPersonName | undefined) =>
	`${name?.firstName || ''} ${name?.middleName || ''} ${name?.lastName || ''
	}`.trim();
		
export const mapToData = <T>(
	data: T[],
	getProperties: (d: T) => PropertyDescriptorMap & ThisType<any>
): T[] => data.map((d) => Object.defineProperties<T>({ ...d }, getProperties(d)));

export const getLocaleDate = (date?: Date | string): string => {

	const newDate = new Date(date || '');
	const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;

	const dateObj = new Date(newDate.getTime() + userTimezoneOffset);
	if(!date || isNaN(dateObj.valueOf()) || (dateObj < new Date('1900-01-01'))) return '';
	return new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric'}).format(dateObj);
};

export const getLocaleDateTime = (date?: Date | string, pattern?: string): string => {
	if(!date) return '';

	const dateObj = new Date(date);

	if(isNaN(dateObj?.valueOf()) || (dateObj < new Date('1900-01-01'))) return '';
	

	return format(dateObj, pattern || 'MM/dd/yyyy \'at\' p');
};

export const getFileExtension = (fileName: string) => {
	const lastDotIndex = fileName.lastIndexOf('.');

	if (lastDotIndex !== -1) {
		return fileName.slice(lastDotIndex);
	} else {
		return '';
	}
};

export const getExpireStatusInWeeksDays = (rawEndDate: Date | string): { status: string, isActive: boolean, totalDaysLeft: number } => {
	const endDate = new Date(rawEndDate);
	const today = new Date().setHours(0, 0, 0, 0);
	const weeks = differenceInWeeks(new Date(endDate), today);
	const days = differenceInDays(new Date(endDate), today) - (weeks * 7);

	const isActive = (weeks > 0) || (days > 0);

	const status = isActive
		? `${weeks ? weeks + 'W' : ''} ${days ? days + 'D' : ''} ${weeks || days ? 'LEFT ' : ''
		}`
		: 'Expired';
	return { status, isActive, totalDaysLeft: differenceInDays(endDate, today) };
};

export const computeEndDate = (date: Date, type: string): Date => {
	const MAX_USE_MONTHS = 21;
	const MAXIMUM_USE = new RegExp(/(Maximum Use)+$/, 'gi');
	const UNLIMITED = new RegExp(/(Unlimited|in perpetuity)+$/, 'gi');
	const YEARS = new RegExp(/(years|year)+$/, 'gi');
	const MONTHS = new RegExp(/(Months|Month)+$/, 'gi');
	const WEEKS = new RegExp(/(weeks|week)+$/, 'gi');
	const DAYS = new RegExp(/(days|day)+$/, 'gi');
	const DateObj = new Date(date);

	let endDate = addDays(DateObj, 1);

	switch (true) {
	case MAXIMUM_USE.test(type): endDate = add(DateObj, { months: MAX_USE_MONTHS }); break;
	case UNLIMITED.test(type): endDate = add(DateObj, { months: MAX_USE_MONTHS }); break;
	case YEARS.test(type): endDate = add(DateObj, { years: parseInt(type.split(' ')[0]) }); break;
	case MONTHS.test(type): endDate = add(DateObj, { months: parseInt(type.split(' ')[0]) }); break;
	case WEEKS.test(type): endDate = add(DateObj, { weeks: parseInt(type.split(' ')[0]) }); break;
	case DAYS.test(type): endDate = add(DateObj, { days: parseInt(type.split(' ')[0]) }); break;
	default: endDate = addDays(DateObj, 1); break;
	}
	// NOTE: For the calculated end date we are subtracting 1 day (Bug ID: 24068)
	if(endDate > DateObj) {
		endDate = addDays(endDate, -1);
	}
	
	return endDate;
};

export const getCycleLengthData = (cycleLength: string) => {
	const MAX_USE_MONTHS = 21;
	const MAXIMUM_USE = new RegExp(/(Maximum Use)+$/, 'gi');
	const UNLIMITED = new RegExp(/(Unlimited)+$/, 'gi');
	const YEARS = new RegExp(/(years|year)+$/, 'gi');
	const MONTHS = new RegExp(/(Months|Month)+$/, 'gi');
	const WEEKS = new RegExp(/(weeks|week)+$/, 'gi');
	const DAYS = new RegExp(/(days|day)+$/, 'gi');

	switch (true) {
	case MAXIMUM_USE.test(cycleLength): return { unit: ASSET_USE_RIGHT_CYCLE_UNIT.MONTHS, period: MAX_USE_MONTHS,};
	case UNLIMITED.test(cycleLength): return { unit: ASSET_USE_RIGHT_CYCLE_UNIT.MONTHS, period: MAX_USE_MONTHS};
	case YEARS.test(cycleLength): return { unit: ASSET_USE_RIGHT_CYCLE_UNIT.YEARS , period: parseInt(cycleLength.split(' ')[0]) ?? 1};
	case MONTHS.test(cycleLength): return { unit: ASSET_USE_RIGHT_CYCLE_UNIT.MONTHS, period: parseInt(cycleLength.split(' ')[0]) ?? 1};
	case WEEKS.test(cycleLength): return { unit: ASSET_USE_RIGHT_CYCLE_UNIT.WEEKS, period: parseInt(cycleLength.split(' ')[0]) ?? 1};
	case DAYS.test(cycleLength): return { unit: ASSET_USE_RIGHT_CYCLE_UNIT.DAYS, period: parseInt(cycleLength.split(' ')[0]) ?? 1};
	default: return { unit: ASSET_USE_RIGHT_CYCLE_UNIT.DAYS,  period: 1};
	}
};

const getNextHoldingFee = (assetRightsTrackerData: any) => {
	const holdingFee = assetRightsTrackerData?.sessions?.find((s: any) =>
		s?.useRightType?.toLowerCase()?.startsWith('holding fee')
	);
	return (
		holdingFee?.cycleExpireDate &&
		addDays(new Date(holdingFee?.cycleExpireDate), 1)
	);
};

const getCycleExpiring = (assetRightsTrackerData: any, place: number) => {
	const sessions = assetRightsTrackerData?.sessions?.filter(
		(s) => !s?.useRightType?.toLowerCase()?.startsWith('holding fee')
	);
	return sessions?.at(place);
};

export const getEmailDetails = (expirationType: any, data: any) => {
	let emailData;
	const continentsOptions = Object.keys(continents).map((key) =>
	{
		return {
			value: key,
			label: continents[key]
		};
	});
		
	const getRegionName = (regionCode: string) => {
		return continentsOptions.find(e => e.value == regionCode)?.label;
	};
	const guaranteeContractName = data?.talentData
		?.flatMap(p => p?.guaranteeTermDetails || [])
		?.flatMap(g => g?.contractName || []) || [];
					
	if (ExpirationType.HoldingFeesDue == expirationType) {
		const talentByCategory = data.talentData.map((talent) => {
			if(talent?.asset?.castForData?.employmentType){
				return talent?.asset?.castForData?.employmentType;
			}
		});
		const talentByCategoryCounts = {};

		talentByCategory?.forEach(talent => {
			talentByCategoryCounts[talent] = (talentByCategoryCounts[talent] || 0) + 1;
		});
		const talentResult = Object.entries(talentByCategoryCounts)?.map(([key, value]) => `${value} ${key}`)?.join(', ') || '';
		const holdingFeeDueEmailData: any = {
			commercialId: data?.assetData?.adId,
			assetProfileId: data?.assetData?.id,
			numberOfVersions: data?.assetData?.editVersions?.length,
			title: data?.assetData?.title,
			advertiser: data?.assetData?.advertisers.length > 0 ? getCompanyDisplayName(data?.assetData?.advertisers[0]?.companyNames) : '',
			adAgency: getCompanyDisplayName(data?.assetData?.adAgency?.companyNames) || '',
			nextHFDueDate: getLocaleDate(data?.expirationDate) || '',
			noOfTalentByCategory: talentResult || '',
			SAGAFTRAMaximumPeriodOfUse: getLocaleDate(data?.assetData?.expireMpuEndDate) || '',
			nextHoldingFeeDue: data?.assetRightsTrackerDetails ? getNextHoldingFee(data?.assetRightsTrackerDetails) : '',
			earliestUseCycleExpiring: getLocaleDate(getCycleExpiring(data?.assetRightsTrackerDetails, 0)?.cycleExpireDate),
			earliestTalentExpiration: getLocaleDate(
				data?.assetRightsTrackerDetails?.casts?.at(0)?.castForData
					?.expireMpuEndDate ?? data?.assetRightsTrackerDetails?.expireMpuEndDate
			) || '-',
			guaranteeTermExpiration: getLocaleDate(data?.earliestGuaranteeTermExpire) || '-',
			earliestElementExpiration: getLocaleDate(
				data?.assetRightsTrackerDetails?.elements
					?.at(0)
					?.rightsAcquiredModel?.at(0)?.endDate
			) || '-',
			dueForTalent: getPersonFullName(data?.talentData?.personName),
			expirationType: expirationType,
			talentData: data?.talentData,
			alertId: data?.id,
			contractType: guaranteeContractName?.at(0) || data?.contractType || '',
		};
		emailData = holdingFeeDueEmailData;
	}
	else if (ExpirationType.UsageRight == expirationType) {
		if (data?.assetData) {
			const useRightsData: any = {
				commercialId: data?.assetData?.adId,
				title: data?.assetData?.title,
				assetProfileId: data?.assetData?.id,
				advertiser: data?.assetData?.advertisers?.length >= 1 ? getCompanyDisplayName(data?.assetData?.advertisers[0]?.companyNames) : '',
				adAgency: getCompanyDisplayName(data?.assetData?.adAgency?.companyNames) || '',
				contractType: guaranteeContractName?.at(0) || data?.contractType || '',
				maximumPeriodOfUse: getLocaleDate(data?.assetData?.expireMpuEndDate) || '',
				nextHoldingFeeDue: data?.assetRightsTrackerDetails ? getNextHoldingFee(data?.assetRightsTrackerDetails) : '',
				earliestTalentExpiration: getLocaleDate(
					data?.assetRightsTrackerDetails?.casts?.at(0)?.castForData
						?.expireMpuEndDate ?? data?.assetRightsTrackerDetails?.expireMpuEndDate
				) || '-',
				guaranteeTermExpiration:  getLocaleDate(data?.earliestGuaranteeTermExpire) || '-',
				earliestElementExpiration: getLocaleDate(
					data?.assetRightsTrackerDetails?.elements
						?.at(0)
						?.rightsAcquiredModel?.at(0)?.endDate
				) || '-',
				expriyDate: getLocaleDate(data?.expirationDate) || '',
				useType: data?.useExpirationSessionData?.useRightType,
				expirationType: expirationType,
				alertId: data?.id,
				description: data?.description
			};
			emailData = useRightsData;
		}
		else {
			const useRightsData: any = {
				commercialId: data?.useExpirationData?.element?.elementId,
				title: data?.useExpirationData?.element?.elementName,
				elementProfileId: data?.elementData?.id,
				advertiser: getCompanyDisplayName(data?.elementData?.onBehalfOfAdvisor?.companyNames) || '',
				adAgency: getCompanyDisplayName(data?.elementData?.adAgency?.companyNames) || '',
				contractType: guaranteeContractName?.at(0) || data?.contractType || '',
				expriyDate: getLocaleDate(data?.expirationDate),
				useType: data?.useExpirationData?.mediaType,
				expirationType: expirationType,
				alertId: data?.id,
				description: data?.description,
				guaranteeTermExpiration: getLocaleDate(data?.earliestGuaranteeTermExpire) || '-',
			};
			emailData = useRightsData;
		}
	}
	else if (ExpirationType.Contract == expirationType) {
		const contractData: any = {
			commercialId: data?.commercials ? data?.commercials[0]?.adId || '' : '',
			title: data?.commercials ? data?.commercials[0]?.title || '' : '',
			compensation: data?.compensation,
		};
		emailData = contractData;
	}
	else if(ExpirationType.ElementExpire == expirationType){
		const elementExpiringData: any = {
			advertiser: getCompanyDisplayName(data?.companyData?.companyNames) || '',
			adAgency: getCompanyDisplayName(data?.adAgencyData?.companyNames) || '',
			elementId: data?.elementData?.elementId || null,
			elementName: data?.elementData?.elementName || '',
			elementType: data?.elementData?.elementType || '',
			licensor: getCompanyDisplayName(data?.lincensorCompanyDetails[0]?.companyNames) || '',
			mediaType: data?.rightsAcquiredData[0]?.mediaType[0] || '',
			territory: getRegionName(data?.territories[0]?.region) || '',
			countries: CountriesData?.getCountryByCode(data?.territories[0]?.countries[0])?.name || '',
			talentData: data?.talentData?.length,
			expirationDate: getLocaleDate(data?.expirationDate) || '',
		};
		emailData = elementExpiringData;
	}
	else {
		const mpuExpiringData: any = {
			commercialId: data?.assetData?.adId,
			numberOfVersions: data?.assetData?.editVersions?.length,
			assetProfileId: data?.assetData?.id,
			title: data?.assetData?.title,
			advertiser: data?.advertiser || '',
			adAgency: getCompanyDisplayName(data?.assetData?.adAgency?.companyNames) || '',
			contractType: guaranteeContractName?.at(0) || data?.contractType || '',
			SAGAFTRAMaximumPeriodOfUse: getLocaleDate(data?.assetData?.expireMpuEndDate) || '',
			maximumPeriodOfUse: getLocaleDate(data?.assetData?.expireMpuEndDate) || '',
			nextHoldingFeeDue: data?.assetRightsTrackerDetails ? getNextHoldingFee(data?.assetRightsTrackerDetails) : '',
			earliestTalentExpiration: getLocaleDate(
				data?.assetRightsTrackerDetails?.casts?.at(0)?.castForData
					?.expireMpuEndDate ?? data?.assetRightsTrackerDetails?.expireMpuEndDate
			) || '-',
			guaranteeTermExpiration: getLocaleDate(data?.earliestGuaranteeTermExpire) || '-',
			earliestElementExpiration: getLocaleDate(
				data?.assetRightsTrackerDetails?.elements
					?.at(0)
					?.rightsAcquiredModel?.at(0)?.endDate
			) || '-',
			expriyDate: getLocaleDate(data?.expirationDate),
			expirationType: expirationType,
			alertId: data?.id
		};
		emailData = mpuExpiringData;
	}
	return {
		emailData
	};
};

export const getEmailContent = (expirationType, data) => {
	let emailContent = ExpirationTypeEmailTemplate[expirationType] || '';
	emailContent = emailContent.replace('-client-', 'Client');
	emailContent = data?.assetProfileId ?
		emailContent.replace('-commercialId-', `<a target="_blank" href=${window.location.origin}/#/asset/${data?.assetProfileId}>${data?.commercialId}</a>`) :
		emailContent.replace('-commercialId-', `<a target="_blank" href=${window.location.origin}/#/elements/${data?.elementProfileId}>${data?.commercialId}</a>`);
	emailContent = emailContent.replace('-title-', data?.title || '');

	if (ExpirationType.HoldingFeesDue == expirationType) {
		emailContent = emailContent.replace('-numberOfVersions-', data.numberOfVersions || '');
		emailContent = emailContent.replace('-nextHfDueDate-', data.nextHFDueDate);
		emailContent = emailContent.replace('-talents-', data.dueForTalent);
		emailContent = emailContent.replace('-advertiser-', data?.advertiser || '');
		emailContent = emailContent.replace('-adAgency-', data?.adAgency || '');
		emailContent = emailContent.replace('-contractType-', data?.contractType || '');
		emailContent = emailContent.replace('-noOfTalentByCategory-', data?.noOfTalentByCategory || '');
		emailContent = emailContent.replace('-SAGAFTRAMaximumPeriodOfUse-', data?.SAGAFTRAMaximumPeriodOfUse || '');
		emailContent = emailContent.replace('-nextHoldingFeeDue-', data?.nextHoldingFeeDue || '');
		emailContent = emailContent.replace('-earliestUseCycleExpiring-', data?.earliestUseCycleExpiring || '');
		emailContent = emailContent.replace('-earliestTalentExpiring-', data?.earliestTalentExpiration || '');
		emailContent = emailContent.replace('-guaranteeTermExpiration-', data?.guaranteeTermExpiration || '');
		emailContent = emailContent.replace('-earliestElementExpiring-', data?.earliestElementExpiration || '');
	}
	else if (ExpirationType.UsageRight == expirationType) {
		emailContent = emailContent.replace('-useType-', data.useType);
		emailContent = emailContent.replace('-advertiser-', data?.advertiser || '');
		emailContent = emailContent.replace('-adAgency-', data?.adAgency || '');
		emailContent = emailContent.replace('-contractType-', data?.contractType || '');
		emailContent = emailContent.replace('-expiryDate-', data.expriyDate);
		emailContent = emailContent.replace('-maximumPeriodOfUse-', data?.maximumPeriodOfUse || '');
		emailContent = emailContent.replace('-nextHoldingFeeDue-', data?.nextHoldingFeeDue || '');
		emailContent = emailContent.replace('-earliestTalentExpiration-', data?.earliestTalentExpiration || '');
		emailContent = emailContent.replace('-guaranteeTermExpiration-', data?.guaranteeTermExpiration || '');
		emailContent = emailContent.replace('-earliestElementExpiration-', data?.earliestElementExpiration || '');
		emailContent = emailContent.replace('-description-', data?.description || '');
	}
	else if (ExpirationType.MPU == expirationType) {
		emailContent = emailContent.replace('-numberOfVersions-', data.numberOfVersions || '');
		emailContent = emailContent.replace('-advertiser-', data?.advertiser || '');
		emailContent = emailContent.replace('-adAgency-', data?.adAgency || '');
		emailContent = emailContent.replace('-contractType-', data?.contractType || '');
		emailContent = emailContent.replace('-SAGAFTRAMaximumPeriodOfUse-', data?.SAGAFTRAMaximumPeriodOfUse || '');
		emailContent = emailContent.replace('-nextHoldingFeeDue-', data?.nextHoldingFeeDue || '');
		emailContent = emailContent.replace('-earliestTalentExpiration-', data?.earliestTalentExpiration || '');
		emailContent = emailContent.replace('-guaranteeTermExpiration-', data?.guaranteeTermExpiration || '');
		emailContent = emailContent.replace('-earliestElementExpiration-', data?.earliestElementExpiration || '');
	}
	else if (ExpirationType.Contract == expirationType) {
		emailContent = emailContent.replace('-numberOfSpots-', data?.numberOfSpots || '-');
		emailContent = emailContent.replace('-compensation-', data?.compensation || '-');
	}
	else if(ExpirationType.ElementExpire == expirationType){
		emailContent = emailContent.replace('-advertiser-', data?.advertiser || '');
		emailContent = emailContent.replace('-adAgency-', data?.adAgency || '');
		emailContent = emailContent.replace('-elementId-',data?.elementId || '');
		emailContent = emailContent.replace('-elementName-',data?.elementName || '');
		emailContent = emailContent.replace('-elementType-',data?.elementType || '');
		emailContent = emailContent.replace('-licensor-',data?.licensor || '');
		emailContent = emailContent.replace('-mediaType-',data?.mediaType);
		emailContent = emailContent.replace('-territory-',data?.territory);
		emailContent = emailContent.replace('-countries-',data?.countries);
		emailContent = emailContent.replace('-talentData-',data?.talentData);
		emailContent = emailContent.replace('-expirationDate-',data?.expirationDate);
	}
	return emailContent;
};

export const 	getComputedCycleLength = (days: number): string => {
	const start = days - 6;
	const end = days + 6;

	const inRange = (day: number): boolean => (start <= day && day <= end) || (days < day);

	switch (true) {
	case inRange(SessionCycleLength.WEEKS_1):
		return DateSessionMapping[SessionCycleLength.WEEKS_1];
	case inRange(SessionCycleLength.WEEKS_4):
		return DateSessionMapping[SessionCycleLength.WEEKS_4];
	case inRange(SessionCycleLength.DAYS_30):
		return DateSessionMapping[SessionCycleLength.DAYS_30];
	case inRange(SessionCycleLength.WEEKS_8):
		return DateSessionMapping[SessionCycleLength.WEEKS_8];
	case inRange(SessionCycleLength.WEEKS_13):
		return DateSessionMapping[SessionCycleLength.WEEKS_13];
	case inRange(SessionCycleLength.WEEKS_26):
		return DateSessionMapping[SessionCycleLength.WEEKS_26];
	case inRange(SessionCycleLength.WEEKS_52):
		return DateSessionMapping[SessionCycleLength.WEEKS_52];
	case inRange(SessionCycleLength.YEAR_1):
		return DateSessionMapping[SessionCycleLength.YEAR_1];
	case inRange(SessionCycleLength.MONTHS_18):
		return DateSessionMapping[SessionCycleLength.MONTHS_18];
	case inRange(SessionCycleLength.MONTHS_21):
		return DateSessionMapping[SessionCycleLength.MONTHS_21];
	case inRange(SessionCycleLength.YEARS_2):
		return DateSessionMapping[SessionCycleLength.YEARS_2];
	case inRange(SessionCycleLength.YEARS_3):
		return DateSessionMapping[SessionCycleLength.YEARS_3];
	default:
		return 'Maximum Use';
	}
};

export const getAssetLength = (value: string) => {
	const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
	const match = value.match(regex);
	if (match) {
		const month = parseInt(match[1], 10) - 1; // Months are zero-based in JavaScript
		const day = parseInt(match[2], 10);
		const year = parseInt(match[3], 10);
		const hours = parseInt(match[4], 10);
		const minutes = parseInt(match[5], 10);
		const seconds = parseInt(match[6], 10);
		const dateObj = new Date(year, month, day, hours, minutes, seconds);
		return getTime(dateObj);
	} else {
		return value ? getFormattedTime(new Date(value))?.toUpperCase() : '-';
	}
};

export const getAssetLengthDateTimeString = (value) => {
	const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
	const match = value.match(regex);
	if (match) {
		const month = parseInt(match[1], 10) - 1; // Months are zero-based in JavaScript
		const day = parseInt(match[2], 10);
		const year = parseInt(match[3], 10);
		const hours = parseInt(match[4], 10);
		const minutes = parseInt(match[5], 10);
		const seconds = parseInt(match[6], 10);
		const dateObj = new Date(year, month, day, hours, minutes, seconds);
		const userTimezoneOffset = dateObj.getTimezoneOffset() * 60000;
		return new Date(dateObj.getTime() - userTimezoneOffset);
	} else {
		return value;
	}
};

export const getFormattedTime = (date: Date, casing: 'UPPER' | 'LOWER' | 'DEFAULT' = 'UPPER'): string => {
	const hours = '0';
	const minutes = (date?.getUTCMinutes()|| 0)?.toString().padStart(2, '0') || '';
	const seconds = (date?.getUTCSeconds()|| 0)?.toString().padStart(2, '0') || '';

	switch (casing) {
	case 'UPPER': return `${parseInt(hours) > 0 ? `${hours}HR :` : ''} ${minutes}MIN : ${seconds}SEC`?.toUpperCase();
	case 'LOWER': return `${parseInt(hours) > 0 ? `${hours}hr :` : ''} ${minutes}min : ${seconds}sec`?.toLowerCase();
	case 'DEFAULT': return `${parseInt(hours) > 0 ? `${hours}hr :` : ''} ${minutes}min : ${seconds}sec`;
	}
};

export const getTime = (date: Date, casing: 'UPPER' | 'LOWER' | 'DEFAULT' = 'UPPER'): string => {
	const hours = '0';
	const minutes = date?.getMinutes()?.toString().padStart(2, '0') || '';
	const seconds = date?.getSeconds()?.toString().padStart(2, '0') || '';

	switch (casing) {
	case 'UPPER': return `${parseInt(hours) > 0 ? `${hours}hr :` : ''} ${minutes}min : ${seconds}sec`?.toUpperCase();
	case 'LOWER': return `${parseInt(hours) > 0 ? `${hours}hr :` : ''} ${minutes}min : ${seconds}sec`?.toLowerCase();
	case 'DEFAULT': return `${parseInt(hours) > 0 ? `${hours}hr :` : ''} ${minutes}min : ${seconds}sec`;
	}
};

export const findDifferingProperties = (obj1: any, obj2: any, parentKey: string = '') => {
	const differingProperties = {};
	for (const key in obj1) {
		const fullKey = parentKey ? `${parentKey}.${key}` : key;

		if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
			if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
				differingProperties[fullKey] = obj2[key];
			}
		} else if (
			typeof obj1[key] === 'object' &&
			typeof obj2[key] === 'object'
		) {
			const nestedDifferences = findDifferingProperties(
				obj1[key],
				obj2[key],
				fullKey
			);
			if (Object.keys(nestedDifferences).length > 0) {
				differingProperties[fullKey] = nestedDifferences;
			}
		} else if (obj1[key] !== obj2[key]) {
			differingProperties[fullKey] = obj2[key];
		}
	}

	return differingProperties;
};

export const getCountryName = (countryCode: string) => Country.getCountryByCode(countryCode)?.name?.toUpperCase() || '';
export const getStateName = (countryCode: string, stateCode: string) => State.getStateByCodeAndCountry(stateCode, countryCode)?.name?.toUpperCase() || '';

export const getBaseUrl = (url: string) => {
	const pathArray = url.split('/');
	const protocol = pathArray[0];
	const host = pathArray[2];
	return protocol + '//' + host;
};

export const formattedPhoneNumber = (countryCode, phoneNumber,phoneNumberLabel?) => {
	const phoneUtil = PhoneNumberUtil.getInstance();
	try {
		const parsedNumber = phoneUtil.parse(`+${countryCode}${phoneNumber}`, 'ZZ');
		let formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
		// Replace spaces with hyphens
		formattedNumber = formattedNumber.replace(/\s/g, '-');
		return `${formattedNumber}`;
	} catch (error) {
		return `+${countryCode}-${phoneNumber}`;
	}
};

export const getFormattedCurrency = (data?: number, maximumFractionDigits: number = 2, currency: string = 'USD'): string => Number(data?.toString() || '0')?.toLocaleString('en-US', { currency, style: 'currency', minimumFractionDigits: 2, maximumFractionDigits  }) || '';

export 	const getNumberValue = (value: number | string | undefined): number => isNaN(Number(value)) ? 0 : Number(value);

export const getSessionTotalInvoice = (invoice: ISessionInvoice | ISessionInvoiceForm): number => {
	const totalPH = [...(invoice?.talentWages || [])]?.reduce(
		(p, c) => p + getNumberValue(c?.pensionAndHealthContribution),
		0
	) ?? 0;

	const totalInvoice = getNumberValue(invoice?.payrollTax) +
		getNumberValue(invoice?.signatoryFee) +
		getNumberValue(invoice?.serviceFee) +
		getNumberValue(invoice?.miscellaneousPayment) +
		getNumberValue(invoice?.grossWage) +
		totalPH;
	return totalInvoice;
};

/**
	* Pass the blob url to detect weather the url is of type Image.
	* @param {string} url - Blob url
*/
export const isImage = (url: string) => {
	const img = new Image();
	img.src = url;

	return new Promise<boolean>((resolve, reject) => {
		img.onload = () => resolve(true);
		img.onerror = () => reject(false);
	});
};

export function dataUriToFile(dataUri: any, fileName: string) {
	// eslint-disable-next-line prefer-const
	let arr: any = dataUri.split(','),
		// eslint-disable-next-line prefer-const
		mime = arr[0].match(/:(.*?);/)[1],
		// eslint-disable-next-line prefer-const
		bstr = atob(arr[arr.length - 1]),
		n = bstr.length,
		// eslint-disable-next-line prefer-const
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], fileName, { type: mime });
}

export const generateUniquePassword = () => {
	let pass = '';
	const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        'abcdefghijklmnopqrstuvwxyz0123456789@#$';

	for (let i = 1; i <= 8; i++) {
		const char = Math.floor(Math.random()
            * str.length + 1);

		pass += str.charAt(char);
	}
	return pass;
};

export const mutateContractAdditionalFormsData = async (data: IContractAdditionalFormMutatePayload, personId?: string, onErrorCb?: CallableFunction) => {
	// NOTE: These linked form's handled separately because the payload size is huge.
	const updatedIds: IContractAdditionalFormMutateReturn = {};
	try {
		// NOTE: Handling the W4 short version form Add/Update
		if(data?.contractW4Form) {
			const contractW4 = await mutateW4Form({...(data?.contractW4Form || {}), personId: personId || ''});
	
			if (contractW4?.status === HttpStatusCode.Ok) {
				updatedIds.w4FormId = contractW4?.data?.id;
				onErrorCb && contractW4.data?.error?.errorMessage && onErrorCb(contractW4.data?.error?.errorMessage);
			}
		}
		// NOTE: Handling the W8-Ben-short version form Add/Update
		if(data?.contractW8BenShortForm) {
			const contractW8BenShort = await mutateW8BenShortForm(data?.contractW8BenShortForm);
	
			if (contractW8BenShort?.status === HttpStatusCode.Ok) {
				updatedIds.w8BenShortFormId = contractW8BenShort?.data?.id;
				onErrorCb && contractW8BenShort.data?.error?.errorMessage && onErrorCb(contractW8BenShort.data?.error?.errorMessage);
			}
		}
		// NOTE: Handling the W9-Ben-short version form Add/Update
		if(data?.contractW9BenShortForm) {
			const contractW9BenShort = await mutateW9BenShortForm(data?.contractW9BenShortForm);
	
			if (contractW9BenShort?.status === HttpStatusCode.Ok) {
				updatedIds.w9BenShortFormId = contractW9BenShort?.data?.id;
				onErrorCb && contractW9BenShort.data?.error?.errorMessage && onErrorCb(contractW9BenShort.data?.error?.errorMessage);
			}
		}
		// NOTE: Handling the Minor-Trust form Add/Update
		if(data?.contractMinorTrustForm) {
			const contractMinorTrustForm = await mutateMinorTrustForm(data?.contractMinorTrustForm);
	
			if (contractMinorTrustForm?.status === HttpStatusCode.Ok) {
				updatedIds.minorTrustFormId = contractMinorTrustForm?.data?.id;
				onErrorCb && contractMinorTrustForm.data?.error?.errorMessage && onErrorCb(contractMinorTrustForm.data?.error?.errorMessage);
			}
		}
			
		// NOTE: Handling the NY-Theft-prevention form Add/Update
		if(data?.contractNYTheftPreventionForm) {
			const contractNYTheftPreventionForm = await mutateNYTheftPreventionForm(data?.contractNYTheftPreventionForm);
	
			if (contractNYTheftPreventionForm?.status === HttpStatusCode.Ok) {
				updatedIds.nYTheftPreventionFormId = contractNYTheftPreventionForm?.data?.id;
				onErrorCb && contractNYTheftPreventionForm.data?.error?.errorMessage && onErrorCb(contractNYTheftPreventionForm.data?.error?.errorMessage);
			}
		}
			
		// NOTE: Handling the CA-Theft-prevention form Add/Update
		if(data?.contractCATheftPreventionForm) {
			const contractCATheftPreventionForm = await mutateCATheftPreventionForm(data?.contractCATheftPreventionForm);
	
			if (contractCATheftPreventionForm?.status === HttpStatusCode.Ok) {
				updatedIds.cATheftPreventionFormId = contractCATheftPreventionForm?.data?.id;
				onErrorCb && contractCATheftPreventionForm.data?.error?.errorMessage && onErrorCb(contractCATheftPreventionForm.data?.error?.errorMessage);
			}
		}
	}
	catch{
		onErrorCb && onErrorCb('Error while adding the Additional forms');
	}
	return updatedIds;
}; 

/**
 * Generates PDF pages from an HTML element using jsPDF and html2canvas.
 * 
 * @param doc - The jsPDF instance to add the generated pages to.
 * @param element - The HTML element to generate the PDF pages from.
 * @param startInNextPage - Optional. Specifies whether to start the generation in a new page. Default is false.
 * @returns The modified jsPDF instance with the generated pages.
 */
export const handlePdfPageGeneration = async(doc: jsPDF, element: HTMLElement, startInNextPage: boolean = false) => {
	
	const canvas = await html2canvas(element, { 
		logging: false, 
		removeContainer: true, 
		windowWidth: 1300,
		windowHeight: 720
	});

	const imgData = canvas.toDataURL('image/png');
	const imgWidth = 210;
	const pageHeight = 295;
	const imgHeight = (canvas.height * imgWidth / canvas.width);
	let heightLeft = imgHeight;
	let position = 0;

	startInNextPage && doc.addPage();
	doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, `img-${position}-${Math.random()}`,'FAST');
	
	heightLeft -= pageHeight;
	
	while (heightLeft >= 0) {
		position = heightLeft - imgHeight;
		doc.addPage();
		doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, `img-${position}-${Math.random()}`, 'FAST');
		heightLeft -= pageHeight;
	}

	return doc;
};

export const getUnionTypeFromFormName = (contractForm: string): string => {
	for (const contractType in ContractAgreementMapping) {
		if (ContractAgreementMapping[contractType].flat().includes(contractForm)) {
			return contractType;
		}
	}
	return '';
};

export const getPerformerCategoryForContract = (baseContract: any): string[] => {
	const categories: string[] = [];
	let contract:any;
	switch(baseContract?.contractName){
	case CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22 : contract = baseContract?.contractSagAftraSdEmpCtrPriComEx1Y22 || []; break;
	case CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22 :contract = baseContract?.contractSagAftraSdEmpCtrComPriPerY22; break;
	case CONTRACT_FORM.AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM : contract = baseContract?.contractSagAftraSdEmpCtrIndEduFilmVideoPgm; break;
	case CONTRACT_FORM.SAG_AFTRA_COED : contract = baseContract?.contractSagAftraCOED; break;
	case CONTRACT_FORM.SAG_AFTRA_TV_PRINCIPAL : contract = baseContract?.contractSagAftraTvPrincipal; break;
	case CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIALS_HIGHLAND_TALENT : contract = baseContract?.contractSagAftraAudioComHT; break;
	case CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_EX2_Y22 : contract = baseContract?.contractSagAftraSdExtComEx2Y22; break;
	case CONTRACT_FORM.SAG_AFTRA_SD_AUD_COM:
	case CONTRACT_FORM.SAG_AFTRA_SD_ACS_AUD_COM_Y22: contract = baseContract?.contractSagAftraSdAudCom; break;
	case CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI:
	case CONTRACT_FORM.NON_UNION_TALENT_AGRMNT:
	case CONTRACT_FORM.NON_UNION_PER_AGRMNT_OFF_CAM_PRI: contract = baseContract?.contractNonUnion; break;
	case CONTRACT_FORM.MSA_TEMPLATE_THREE:
	case CONTRACT_FORM.CREATOR_AGREEMENT:
	case CONTRACT_FORM.INFLUENCER_AGREEMENT: contract = baseContract?.contractMSA; break;
	}	

	contract?.isPrincipalPerformer && categories.push('Principal Performer');
	contract?.isSpecialtyAct && categories.push('Specialty Act');
	contract?.isGroup3To5 && categories.push('Group 3-5');
	contract?.isSignatureSoloOrDuo && categories.push('Signature Solo/Duo');
	contract?.isPilot && categories.push('Pilot');
	contract?.isStuntPerformer && categories.push('Stunt Performer');
	contract?.isDancer && categories.push('Dancer');
	contract?.isGroup6To8 && categories.push('Group 6-8');
	contract?.isGroupSignature3To5 && categories.push('Group Signature 3-5');
	contract?.isSignLanguageInterpreter && categories.push('Sign Language Interpreter');
	contract?.isStuntCoordinator && categories.push('Stunt Coordinator');
	contract?.isSinger && categories.push('Singer');
	contract?.isGroup9OrMore && categories.push('Group 9 or More');
	contract?.isGroupSignature6To8 && categories.push('Group Signature 6-8');
	contract?.isSoloOrDuo && categories.push('Solo/Duo');
	contract?.isContractor && categories.push('Contractor');
	contract?.isGroupSignature9OrMore && categories.push('Group Signature 9 or More');
	contract?.isCharacterVoice && categories.push('Character Voice');
	contract?.isPuppeteer && categories.push('Puppeteer');
	contract?.isSignature && categories.push('Signature');
	contract?.isDancerGroup3To5 && categories.push('Dancer Group 3-5');
	contract?.isGroupSigner6To8 && categories.push('Group Signer 6-8');
	contract?.isGroupSinger9OrMore && categories.push('Group Singer 9 or More');
	contract?.isDayPerformer && categories.push('Day Performer');
	contract?.isHalfDayPerformer && categories.push('Half Day Performer');
	contract?.isSingerSoloOrDuo && categories.push('Singer Solo/Duo');
	contract?.isGeneralBackgroundActor && categories.push('General Background Actor');
	contract?.isThreeDayPerformer && categories.push('Three Day Performer');
	contract?.isDancerSoloOrDuo && categories.push('Dancer Solo/Duo');
	contract?.isGroupSinger && categories.push('Group Singer');
	contract?.isSpecialAbilityBackgroundActor && categories.push('Special Ability Background Actor');
	contract?.isWeeklyPerformer && categories.push('Weekly Performer');
	contract?.isGroupDancer && categories.push('Group Dancer');
	contract?.isStepOutSinger && categories.push('Step Out Singer');
	contract?.isSilentBitBackgroundActor && categories.push('Silent Bit Background Actor');
	contract?.isCommercialExtraPerformer && categories.push('Commercial Extra Performer');
	contract?.isHandModel && categories.push('Hand Model');
	contract?.isStandIn && categories.push('Stand In');
	contract?.isPhotoDouble && categories.push('Photo Double');
	contract?.isAnnouncer && categories.push('Announcer');
	contract?.isSpanishLanguageTranslationPerformed && categories.push('Spanish Language Translation Performed');
	contract?.isActor && categories.push('Actor');
	contract?.isGroupSpeaker && categories.push('Group Speaker');
	contract?.isSoundEffects && categories.push('Sound Effects');	

	return categories;
};

/**
 * Note: The Asset/Element length is stored in the seconds format. This function converts the seconds to MM:SS format.
 * @param seconds - The number of seconds to convert.
 * @returns A string representation of minutes and seconds in the format MM:SS.
 */
export const getSecondsInMMSS = (seconds: number, casing: 'UPPER' | 'LOWER' = 'UPPER'): string => {
	const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
	const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
	let timeString = (seconds <= 60 && seconds >= 0) ? `${seconds} sec` : `${minutes} min : ${remainingSeconds} sec`;

	if(!seconds) timeString = '0 sec';
	return casing === 'UPPER' ? timeString.toUpperCase() : timeString;
};

export const isAssetLengthFieldVisible = (fileType?: string) => !['IMAGE', 'PRINT'].some((type) => type === fileType?.toUpperCase());

export const findNodeAndAncestors = (data, keys) => {
	const result: any = [];

	const searchTree = (node, parent: any = null, grandparent: any = null, greatGrandparent: any = null) => {
		let nodeCopy: any = null;

		if (keys?.includes(node.key)) {
			nodeCopy = { ...node };
		}

		if (node?.children) {
			const children: any = [];
			for (const child of node.children) {
				const childResult = searchTree(child, node, parent, grandparent);
				if (childResult) {
					children.push(childResult);
				}
			}

			if (children.length > 0) {
				if (!nodeCopy) {
					nodeCopy = { ...node };
				}
				nodeCopy.children = children;
			}
		}

		if (nodeCopy) {
			if (parent) {
				const parentCopy = result.find(n => n.key === parent.key);
				if (parentCopy) {
					parentCopy.children.push(nodeCopy);
				} else {
					const newParentCopy = { ...parent, children: [nodeCopy] };
					if (grandparent) {
						const grandparentCopy = result.find(n => n.key === grandparent.key);
						if (grandparentCopy) {
							const existingParent = grandparentCopy.children.find(n => n.key === parent.key);
							if (existingParent) {
								existingParent.children.push(nodeCopy);
							} else {
								grandparentCopy.children.push(newParentCopy);
							}
						} else {
							const newGrandparentCopy = { ...grandparent, children: [newParentCopy] };
							if (greatGrandparent) {
								const greatGrandparentCopy = result.find(n => n.key === greatGrandparent.key);
								if (greatGrandparentCopy) {
									const existingGrandparent = greatGrandparentCopy.children.find(n => n.key === grandparent.key);
									if (existingGrandparent) {
										existingGrandparent.children.push(newParentCopy);
									} else {
										greatGrandparentCopy.children.push(newGrandparentCopy);
									}
								} else {
									const newGreatGrandparentCopy = { ...greatGrandparent, children: [newGrandparentCopy] };
									result.push(newGreatGrandparentCopy);
								}
							} else {
								result.push(newGrandparentCopy);
							}
						}
					} else {
						result.push(newParentCopy);
					}
				}
			} else {
				result.push(nodeCopy);
			}
		}

		return nodeCopy;
	};

	for (const node of data) {
		searchTree(node);
	}

	const removeDuplicates = (data: any):any => {
		const nodeMap = new Map<string, any>();

		data.forEach(node => {
			nodeMap.set(node.key, node); // This will overwrite previous entries with the same key, effectively keeping the last one
		});

		return Array.from(nodeMap.values());
	};

	return removeDuplicates(result);
};

/**
 * NOTE: Pagination API with the date filter 'EQUAL' Operator is not working as expected.
 * So we are using the  'CONTAINS' Operator for the date filter with the returned date string.
 * @param date - A date object or a date string
 * @returns A date string for the format YYYY-MM-DD
**/
export const getFilterDateString = (date: Date | string) => {
	const dateObj = new Date(date);
	if(isNaN(dateObj.getTime())) return '';
	return format(dateObj, 'yyyy-MM-dd');
};

export const sortEmailOrPhoneNumberForDropDown = (unorderedData) => {
	const primaryData = unorderedData?.find((e) => !!e?.isPrimary);
	const processedData = unorderedData?.filter((e) => e?.id !== primaryData?.id) || [];
	
	if(primaryData?.id){
		processedData?.unshift(primaryData);
	}
	return processedData || [];
};

export const addressShortner = (addressObj) => {
	return addressObj?.line2 ? (addressObj?.line1.concat(', ', addressObj?.line2) || '') : addressObj?.line1;
};

export const getCurrencyOptions = () => Array.from(
	new Set(Country.getAllCountries().map((c) => c.currency))
).sort()?.map(c => ({ label: c , value: c}));

/**
 * To get the nested contract details based on the contract name
 * @param baseContract Base contract object which contains the nested contract details
 * @returns Corresponding nested contract details based on the contract name
 */
export const getNestedContractDetail = (baseContract: any) => {
	let contract:any = {};
	switch(baseContract?.contractName){
	case CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22 : contract = baseContract?.contractSagAftraSdEmpCtrPriComEx1Y22 || []; break;
	case CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22 :contract = baseContract?.contractSagAftraSdEmpCtrComPriPerY22; break;
	case CONTRACT_FORM.AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM : contract = baseContract?.contractSagAftraSdEmpCtrIndEduFilmVideoPgm; break;
	case CONTRACT_FORM.SAG_AFTRA_COED : contract = baseContract?.contractSagAftraCOED; break;
	case CONTRACT_FORM.SAG_AFTRA_TV_PRINCIPAL : contract = baseContract?.contractSagAftraTvPrincipal; break;
	case CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_EX2_Y22 : contract = baseContract?.contractSagAftraSdExtComEx2Y22; break;
	case CONTRACT_FORM.SAG_AFTRA_SD_AUD_COM:
	case CONTRACT_FORM.SAG_AFTRA_SD_ACS_AUD_COM_Y22: contract = baseContract?.contractSagAftraSdAudCom; break;
	case CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIALS_HIGHLAND_TALENT : contract = baseContract?.contractSagAftraAudioComHT; break;
	case CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI:
	case CONTRACT_FORM.NON_UNION_TALENT_AGRMNT:
	case CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI_TALENT_AGRMNT:
	case CONTRACT_FORM.NON_UNION_PER_AGRMNT_OFF_CAM_PRI: contract = baseContract?.contractNonUnion; break;
	case CONTRACT_FORM.MSA_TEMPLATE_THREE:
	case CONTRACT_FORM.CREATOR_AGREEMENT:
	case CONTRACT_FORM.INFLUENCER_AGREEMENT: contract = baseContract?.contractMSA; break;
	}	
	return contract;
};

/**
 * 
 * @param e Event from the input field of type text
 * @param cb This is the callable function which gets the number type value from the input field
 * @param decimalPlaces Number of decimal places to be rounded off
 */
export const numberOnChangeEventHandler = (e, cb: CallableFunction) => {
	const value = e?.target?.value;
	const isValidNumber = new RegExp(/^\d*\.?\d{0,2}$/).test(value);
	
	if (isValidNumber && Number(value) >= 0) {
		return cb(value);
	}
	
	const numMatch = value.match(/^\d*\.?\d{0,2}/);
	const num = numMatch ? numMatch[0] : '';
	
	if (!isNaN(Number(num))) {
		return cb(num);
	}
	
	return cb(0);
};


/**
 * Retrieves the value of a given key from an object in safe mode.
 * 
 * @param obj - The object to retrieve the value from.
 * @param key - The key to retrieve the value for.
 * @returns The value associated with the given key, or `undefined` if the key does not exist in the object.
 */
export const getValueByKey = (obj, key?: string) => key && Object.prototype.hasOwnProperty.call(obj, key) ? obj[key] : undefined; 

/**
 * 
 * @param occupationType Persons employment/occupation type one of the value from 'OccupationType'
 * @param assetUnionAgreements 
 * @returns - Abbreviated name and control type
 */
export const getCastAbbreviatedName = (occupationType: string, castUnion: string, assetUnionAgreements: IUnionAgreement[]) => {
	const sagAftraUnionAgreements = assetUnionAgreements
		?.filter(a => a?.unionName?.toUpperCase() === UNION_TYPES.SAG_AFTRA && !!a?.unionAgreement)
		?.map(a => a?.unionAgreement?.toUpperCase()) || [];

	const unionType = castUnion?.toUpperCase();

	
	const getAbbreviationResult = (obj) => {
		if(getValueByKey(obj, occupationType)){
			return obj[occupationType];
		}
	};

	const getSagAftraUnionAgreements = () => {
		let agreements = sagAftraUnionAgreements;
		if(!agreements?.length){
			agreements = [
				UNION_AGREEMENTS.TV_COMMERCIAL_ACS, 
				UNION_AGREEMENTS.CORPORATE_EDUCATIONAL_NON_BROADCAST, 
				UNION_AGREEMENTS.AUDIO_ACS, 
				UNION_AGREEMENTS.INFOMERCIAL,
				UNION_AGREEMENTS.TV_AND_RADIO_COMMERCIAL
			];			
		}

		for(const agreement of agreements){
			const agreementAbbreviationObj = getValueByKey(ABBREVIATED_CAST_CATEGORIES_MAPPING, agreement);
			const abbreviationObj = getValueByKey(agreementAbbreviationObj || {}, occupationType);			
			if(agreementAbbreviationObj && abbreviationObj?.abbreviation){
				return getAbbreviationResult(ABBREVIATED_CAST_CATEGORIES_MAPPING[agreement]);
			}
		}

		return { abbreviation: occupationType, control: CAST_ABBREVIATION_CONTROL.BOTH };
	};
	
	switch(unionType){
	case UNION_AGREEMENTS.AFM:  return getAbbreviationResult(ABBREVIATED_CAST_CATEGORIES_MAPPING[UNION_TYPES.AFM]);
	case UNION_AGREEMENTS.NON_UNION:  return getAbbreviationResult(ABBREVIATED_CAST_CATEGORIES_MAPPING[UNION_TYPES.NON_UNION]);
	default: return getSagAftraUnionAgreements();
	}
};

export const getRoundOffValue = (value?: number| string, decimalPlaces: number = 2) => {
	const numObj = value ? parseFloat(`${value}`) : 0;
	return parseFloat(numObj?.toFixed(decimalPlaces)); 
};

export const convertMediaObject = (mediaArray) => {
	let keyCounter = 1;
	return mediaArray.map(media => {
		const mainKey = keyCounter++; 
		return {
			key: String(mainKey),                 
			title: media.mediaType,        
			type: 'MEDIATYPE',             
			children: media.mediaSubType ? [{
				key: String(keyCounter++),              
				title: media.mediaSubType,   
				type: 'MEDIASUBTYPE',        
				children: media.mediaSubTypeDetails.map(detail => ({
					key: String(keyCounter++),             
					title: detail,        
					type: 'MEDIASUBTYPEDETAIL'
				}))
			}] : []
		};
	});
};

/**
 * Schema for lookAHead field.
 *
 * @param field - The name of the field to be in validation message.
 * @returns A Yup schema object with validation rules for the specified field.
 */
export const lookAHeadSchema = (field: string) =>  schema.object({
	value: schema.string().required(`${field} is required.`),
	label: schema.string().required(`${field} is required.`)
})
	.required(`${field} is required.`)
	.typeError(`${field} is required.`)
	.test('look-head-test', `${field} is required.`, (optionObj) => {
		return !!optionObj?.value?.length;
	});

export const getDateRanges = (dates: any[]) => {
	const startTimezoneOffset = dates[0]?.$d?.getTimezoneOffset() * 60000;
	const endTimezoneOffset = dates[1]?.$d?.getTimezoneOffset() * 60000;
	const dateRanges = [
		dates[0] && new Date(dates[0]?.$d?.getTime() - startTimezoneOffset)?.toISOString()?.split('.')[0] + 'Z' || null,
		dates[1] && new Date(dates[1]?.$d?.getTime() - endTimezoneOffset)?.toISOString()?.split('.')[0] + 'Z' || null
	];
	return dateRanges;
};
