import { CloseRounded } from '@mui/icons-material';
import { IconButton } from '@mui/joy';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { forwardRef, useEffect } from 'react';
import styles from './select.module.scss';

export interface ISelectOption {
	value?: any;
	label?: any;
	img?: any;
}

// export interface ISelect extends SelectProps {
// 	value?: any;
// 	label?: any;
// 	defaultValue?: any;
// 	options?: Array<ISelectOption>;
// 	onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
// 	disabled?: boolean;
// 	required?: any;
// 	helperText?: any;
// 	error?: any;
// 	shrink?: any;
// 	placeholder?: any;
// 	id?: any;
// 	name?: any;
// 	convertValueToUpperCase?: boolean;
// 	enableEmptySelection?: boolean;
// 	size?: any;
// }

// eslint-disable-next-line react/display-name
const NabuSelect = forwardRef((props: any, ref: any) => {
	const convertValueToUpperCase = props.convertValueToUpperCase === undefined ? true : props.convertValueToUpperCase;

	const selectOptions = (props?.shouldOverrideDefaultSort ? props?.options : props?.options?.sort((a, b) => a?.label?.toString()?.localeCompare(b?.label?.toString()))) || [];
	// NOTE: This is to select the first option by default. 
	// 'handleDefaultSelection' prop is a callback, that excepts the boolean value in return to select the 1st option on options loaded.
	// It provides the available options as callbacks argument. You can also select the custom logic and return false or undefined to not select the first option.
	useEffect(() => {
		if(props?.handleDefaultSelection && props?.options && props?.options.length > 0) {
			const shouldSelectFirstOne = props?.handleDefaultSelection(props?.options) ?? false;
			if(shouldSelectFirstOne) {
				props?.onChange && props.onChange(props?.options[0]?.value);
			}
		}
	}, [props?.options]);

	return (
		<FormControl fullWidth sx={{}} ref={ref} error={props.error} className={styles.selectInput}>
			{/* <FormLabel
				data-cy={`${props.label}`}
				id='select-field-demo-label'
				htmlFor='select-field-demo-button'
			>
				{props.label}
				{props.required && <sup>*</sup>}
			</FormLabel> */}
			<Select
				id={props.label}
				{...props}
				ref={ref}
				aria-label={props?.name}
				value={props?.value || ''}
				data-cy={`data_cy_${props?.name?.split('.')?.join('_') || 'nabu_select'}`}
				error={false}
				size='small'
				defaultValue={props.defaultValue || ''}
				displayEmpty={true}
				slotProps={{}}
				placeholder={props?.placeholder?.toUpperCase() ?? ''}
				{...(props?.isClearable && props?.value && ({
					endAdornment:<IconButton className={styles.iconButtonSelect}
						size='sm'
						variant='plain'
						onMouseDown={(event) => {
							event.stopPropagation();
						}}
						onClick={() => {
							props?.onChange && props.onChange(undefined);
							props?.onClear && props.onClear();
						}}
					>
						<CloseRounded className={styles.closeIconSelect}/>
					</IconButton>
				}))}
				className={styles.selectDefault}
			>
				{props?.value == undefined ? <MenuItem  disabled={!props?.enableEmptySelection} value={undefined}><div className={styles.placeholderValue}> {props?.placeholder?.toUpperCase() ?? ''} </div></MenuItem> : <MenuItem disabled={!props?.enableEmptySelection} value=''><div className={styles.placeholderValue}>{props?.placeholder?.toUpperCase() ?? ''}</div></MenuItem>}
				{selectOptions?.length > 0 &&
					selectOptions?.map((menuItem: any, index: number) => (
						<MenuItem
							key={`item-${index}`}
							data-cy={`${menuItem?.label}`}
							value={convertValueToUpperCase ? menuItem?.value?.toString()?.toUpperCase() ?? '' : menuItem?.value ?? ''}
						>					
							{(props?.defaultCase ? menuItem?.label?.toString() : menuItem?.label?.toString()?.toUpperCase()) ?? ''}
						</MenuItem>
					))}
			</Select>
			{props.helperText && (
				<FormHelperText data-cy={`${props.helperText}`}>
					{props.helperText}
				</FormHelperText>
			)}
		</FormControl>
	);
});

export default NabuSelect;
