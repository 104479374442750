import { IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import { httpClient } from './httpClient';
import { IDeleteDocumentValue, IDocumentValue, IDocumentViewModel } from './models/company';
import { UserModel } from '@/modules/people/shared/models/person';

import { IAgencyInfo, IPersonRecord, ISocialMediaAddModel, IZipCodeResponse } from './models/person';
import { ISelectedPerson } from '@/store/company/types';
import { ICompanyAddress, ICompanyRecord } from '@/common/types/company';

export const createPerson = (payload: any) => {
	return httpClient.post('/People/Save', payload);
};

export const getAddressBasedOnPinCode = (zipCode: string) => {
	return httpClient.get<IZipCodeResponse | null>(`/People/GetAddressBasedOnZipCode/${zipCode}`);
};

export const addUser = (userModel: UserModel,personId: string) => {
	return httpClient.post(`/User/AddUser/${personId}`,userModel);
};

export const savePersonAsStaff = (payload: any) => {
	return httpClient.post('/People/AddStaff', payload);
};

export const saveLoanOutCorporation = (payload: any) => {
	return httpClient.post('/People/LoanOutCorporation/Save', payload);
};

export const addBank = (payload: any) => {
	return httpClient.post(`/${payload.apiLabel}/Bank/Save`, payload);
};

export const addW4TaxInfo = (payload: any) => {
	return httpClient.post('/People/W4Tax/Save', payload);
};

export const generateForm = (payload: any) => {
	return httpClient.post('/People/TaxInfo/GenerateForm', payload);
};

export const downloadPdf = () => {
	return httpClient.get('/People/TaxInfo/download-pdf');
};

export const addW8TaxInfo = (payload: any) => {
	return httpClient.post('/People/W8Tax/Save', payload);
};

export const addW9TaxInfo = (payload: any) => {
	return httpClient.post('/People/W9Tax/Save', payload);
};

export const addSpecification = (payload: any) => {
	return httpClient.post('/People/Specification/Save', payload);
};

export const addPayrollVendor = (payload: any) => {
	return httpClient.post('/People/PayrollVendor/Save', payload);
};

export const addMinorPermit = (payload: any) => {
	return httpClient.post('/Company/MinorPermit/Save', payload);
};

export const saveSocialMedia = (payload: ISocialMediaAddModel) => {
	return httpClient.post('/People/SocialMedia/Save', payload);
};

export const getAllLoanOutCorporations = (payload: any) => {
	return httpClient.post(`/People/LoanOutCorporations/${payload?.personId}`, payload);
};

export const addInsurance = (payload: any) => {
	return httpClient.post('/Company/Insurance/Save', payload);
};

export const deleteSocialMedia = (payload:any) => {
	return httpClient.delete('/People/SocialMedia/Delete', payload);
};

export const addTransferOfRights = (payload: any) => {
	return httpClient.post('/Company/TransferOfRights/Save', payload);
};

export const getAllMinorPermits = (payload: any) => {
	return httpClient.post('/Company/MinorPermit/GetAll', payload);
};

export const getTransferOfRights = (payload: any) => {
	return httpClient.post('/Company/TransferOfRights/GetAll', payload);
};

export const getInsurancePolicies = (payload: any) => {
	return httpClient.post('/Company/Insurance/GetAll', payload);
};

export const addPhoto = (payload: any) => {
	return httpClient.post('/People/Photo/Save', payload);
};

export const getAllBankDetails = (payload: any) => {
	return httpClient.post(`/${payload.apiLabel}/Bank/GetAll/${payload.personId}`);
};

export const getSpecifications = (payload: any) => {
	return httpClient.post(`/People/Specification/GetAll/${payload.personId}`);
};

export const getAllPayrollVendorDetails = (payload: any) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/People/PayrollVendor/GetAll', payload);
};

export const getTravelDetails = (payload: any) => {
	return httpClient.post(`/People/Travel/GetAll/${payload.personId}`);
};

export const addIDLAndTSA = (payload: any) => {
	return httpClient.post('/People/Travel/BasicInfo/Save', payload);
};

export const addPassport = (payload: any) => {
	return httpClient.post(`/People/Travel/Passport/Save/${payload.travelId}/${payload.personId}`, payload.passportModel);
};

export const addRentalCar = (payload: any) => {
	return httpClient.post(`/People/Travel/RentalCar/Save/${payload.travelId}`, payload.rentalCarModel);
};

export const addHotel = (payload: any) => {
	return httpClient.post(`/People/Travel/Hotel/Save/${payload.travelId}`, payload.hotelModel);
};

export const addAirline = (payload: any) => {
	return httpClient.post(`/People/Travel/Airline/Save/${payload.travelId}`, payload.airlineModel);
};

export const getPhotos = (payload: any) => {
	return httpClient.post(`/People/Photo/GetAll/${payload.personId}`, payload);
};

export const getPersonById = (personId: any) => {
	return httpClient.get<ISelectedPerson>(`/People/${personId}`);
};

export const getPersonTaxInformation = (personId: any) => {
	return httpClient.get(`/People/${personId}/TaxInfo`);
};

export const getLoanOutCorporations = (personId: any, filterModel: any) => {
	return httpClient.post(`/People/LoanOutCorporation/${personId}`, filterModel);
};

export const getPeople = (filterModel: any) => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>('/People/GetAll', filterModel);
};

export const getByWithFullNameSearch = (filterModel: IFilterModel, searchText: string = '') => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>('/People/GetByWithFullNameSearch', { filterModel, searchText });
};

export const GetByWithFullNameSearchForBasicDetail = (filterModel: IFilterModel, searchText: string = '') => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>('/People/GetByWithFullNameSearchForBasicDetail', { filterModel, searchText });
};

export const getUser = (payload: any, personId: string) => {
	return httpClient.post(`/User/${personId}/GetUser`, payload);
};

export const getRelatedAssetsAsCast = (filterModel: any) => {
	return httpClient.post('/People/Relationships/Assets/Cast', filterModel);
};

export const getRelatedAssetsAsCrew = (filterModel: any) => {
	return httpClient.post('/People/Relationships/Assets/Crew', filterModel);
};

export const getRelatedElementLicensor = (filterModel: any) => {
	return httpClient.post('/People/Relationships/Element/Licensors', filterModel);
};

export const getRelatedElementTalent = (filterModel: any) => {
	return httpClient.post('/People/Relationships/Element/Talents', filterModel);
};

export const getRelatedElementRightHolder = (filterModel: any) => {
	return httpClient.post('/People/Relationships/Element/RightsHolder', filterModel);
};

export const getRelatedCompanies = (filterModel: any) => {
	return httpClient.post('/People/Relationships/Companies', filterModel);
};

export const saveContactsForAddress = (payload: any) => {
	return httpClient.post('/People/Address/EmergencyContact/Save', payload);
};

export const createDocument = (payload: IDocumentValue) => {
	return httpClient.post('People/Document/Save', payload);
};

export const getAllUnions = () => {
	return httpClient.get('Union/GetAll');
};

export const deletePerson = (payload: any) => {
	return httpClient.delete('/People/Delete', payload);
};

export const checkPersonHasDependencies = (personId: string) => {
	return httpClient.get(`/People/CheckPersonHasDependencies/${personId}`);
};

export const deleteBank = (payload: any) => {
	return httpClient.delete(`/${payload.apiLabel}/Bank/Delete`, payload);
};

export const deleteSpecification = (payload: any) => {
	return httpClient.delete('/People/Specification/Delete', payload);
};

export const deletePhoto = (payload: any) => {
	return httpClient.delete('/People/Photo/Delete', payload);
};

export const deletePassport = (payload: any) => {
	return httpClient.delete('/People/Travel/Passport/Delete', payload);
};

export const deleteAirline = (payload: any) => {
	return httpClient.delete('/People/Travel/Airline/Delete', payload);
};

export const deleteHotel = (payload: any) => {
	return httpClient.delete('/People/Travel/Hotel/Delete', payload);
};

export const deleteRentalCar = (payload: any) => {
	return httpClient.delete('/People/Travel/RentalCar/Delete', payload);
};

export const deletePayrollVendor = (payload: any) => {
	return httpClient.delete('/People/PayrollVendor/Delete', payload);
};

export const getDocumentList = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<IDocumentViewModel>>(`People/Documents/${id}`, filterModel);
};

export const deleteDocument = (payload: IDeleteDocumentValue) => {
	return httpClient.delete('People/Document/Delete', payload);
};

export const deleteEmergencyContact = (params: any) => {
	return httpClient.delete('/People/Address/EmergencyContact/Delete', params);
};

export const deleteAddress = (params: any) => {
	return httpClient.delete('/People/Address/Delete', params);
};

export const getPeopleBasicDetails = (filterModel: any) => {
	return httpClient.post('/People/GetAll', filterModel);
};

export const deleteLoanOutCorporation = (params: any) => {
	return httpClient.delete('/People/LoanOutCorporation/Delete', params);
};

export const getAllPeopleBasicDetail = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>('/People/GetAllBasicDetails', filters);
};

export const createAgentInformation = (params: any) => {
	return httpClient.post('/People/AgentInformation/Save', params);
};

export const getAllAgentInformation = (filters: IFilterModel, id: any) => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>(`/People/GetAllAgentInformation/${id}`, filters);
};

export const getAgentInfo = (filterModel: IFilterModel, personId: string) => {
	return httpClient.post<IPagedResultModel<IAgencyInfo>>(`/People/GetAgentInfo/${personId}`, filterModel);
};

export const deleteAgentInformation = (id: any) => {
	return httpClient.delete(`/People/DeleteAgentInformation/${id}`);
};

export const updateAgentInformation = (payload: any) => {
	return httpClient.put('/People/UpdateAgentInformation', payload);
};

export const createPersonPhoneNumber = (payload: any) => {
	return httpClient.post('/People/PhoneNumber/Save', payload);
};

export const createPersonEmail = (payload: any) => {
	return httpClient.post('/People/Email/Save', payload);
};

export const getAllAddressByPerson = (filterModel: IFilterModel, personId: string) => {
	return httpClient.post<IPagedResultModel<ICompanyAddress>>(`/People/Address/GetByPerson/${personId}`, filterModel);
};

export const getPersonAgentDetails = (personId: any) => {
	return httpClient.get<any>(`/People/GetPersonAgentDetails/${personId}`);
};
