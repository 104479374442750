import { OPERATORS } from '@/common/enums/index';
import { DefaultPageSize } from '../constants/constants';

export interface IFilterOption {
	value: string;
	property: string;
	operator: OPERATORS;
	isCaseSensitive: boolean;
}

export class FilterOption {
	value: string;
	property: string;
	operator: OPERATORS;
	isCaseSensitive: boolean;

	constructor(value: string, property: string ='Id', operator: OPERATORS = OPERATORS.EQUAL, isCaseSensitive: boolean = false) {
		this.value = value;
		this.property = property;
		this.operator = operator;
		this.isCaseSensitive = isCaseSensitive;
	}
}

export interface IFilterModel {
	pageNumber: number;
	sortField: string;
	pageSize: number;
	sortDescending: boolean;
	filters?: IFilterOption[];
}

export interface IPagedResultModel<T> {
	totalRecords: number;
	records: T[];
}

export interface IErrorModel {
	errorType: number;
	errorMessage: string;
	errorDetails: string[];
}

export class FilterModel {
	pageNumber: number = 0;
	sortField: string = 'Id';
	pageSize: number = DefaultPageSize;
	sortDescending: boolean = false;
	filters?: IFilterOption[];

	constructor(filters: IFilterOption[] = [], sortField: string = 'Id', pageSize: number = DefaultPageSize, sortDescending: boolean = false) {
		this.filters = filters;
		this.sortField = sortField;
		this.pageSize = pageSize;
		this.sortDescending = sortDescending;
	}
}